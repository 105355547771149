/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import firebase from '../firebaseOLD';
import '../css/Archive.css';
import OrdersTableArchive from '../elements/ordersTableArchive';

// Helper function to format date as DD-MM-YYYY
const formatDate = (date) => {
  if (!date) return '';
  const d = new Date(date);
  const day = String(d.getDate()).padStart(2, '0');
  const month = String(d.getMonth() + 1).padStart(2, '0');
  const year = d.getFullYear();
  return `${day}-${month}-${year}`;
};

// Helper function to convert DD-MM-YYYY to YYYY-MM-DD for input type="date"
const convertToHTMLDate = (dateStr) => {
  if (!dateStr) return '';
  const [day, month, year] = dateStr.split('-');
  return `${year}-${month}-${day}`;
};

// Helper function to parse date string to Date object
const parseDate = (dateStr) => {
  if (!dateStr) return new Date(0);
  const [day, month, year] = dateStr.split('-').map(Number);
  return new Date(year, month - 1, day);
};

function Archive({ userRole = '' }) {
  const [allOrders, setAllOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [buyerSearchTerm, setBuyerSearchTerm] = useState('');
  const [commentSearchTerm, setCommentSearchTerm] = useState('');
  const [invoiceSearchTerm, setInvoiceSearchTerm] = useState('');
  const [filterStatus, setFilterStatus] = useState('all');
  const [sortBy, setSortBy] = useState('date');
  const [selectedVendor, setSelectedVendor] = useState('all');
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState({
    startDate: '',
    endDate: ''
  });

  // Helper function to handle date changes
  const handleDateChange = (type, value) => {
    setDateRange(prev => ({
      ...prev,
      [type]: value ? formatDate(value) : ''
    }));
  };

  // Set initial date range (one year ago to today)
  useEffect(() => {
    const today = new Date();
    const oneYearAgo = new Date(today.setFullYear(today.getFullYear() - 1));
    
    setDateRange({
      startDate: formatDate(oneYearAgo),
      endDate: formatDate(new Date())
    });
  }, []);

  // Fetch orders based on date range
  useEffect(() => {
    if (!dateRange.startDate || !dateRange.endDate) return;

    const fetchOrders = async () => {
      setLoading(true);
      const dbRef = firebase.database().ref('orders');
      
      try {
        const snapshot = await dbRef.once('value');
        const ordersData = snapshot.val();

        if (ordersData) {
          // Convert all orders (both base and derivative) to flat array
          const orders = Object.entries(ordersData).map(([key, value]) => ({
            id: key,
            ...value,
            isDerivative: !!value.derivedFrom
          }));

          // Filter orders within date range
          const filteredOrders = orders.filter(order => {
            const [orderDay, orderMonth, orderYear] = (order.OrderDate || '').split('-').map(Number);
            const orderDate = new Date(orderYear, orderMonth - 1, orderDay);
            
            const [startDay, startMonth, startYear] = dateRange.startDate.split('-').map(Number);
            const [endDay, endMonth, endYear] = dateRange.endDate.split('-').map(Number);
            
            const startDate = new Date(startYear, startMonth - 1, startDay);
            const endDate = new Date(endYear, endMonth - 1, endDay);

            return orderDate >= startDate && orderDate <= endDate;
          });

          setAllOrders(filteredOrders);
          setFilteredOrders(filteredOrders);
        } else {
          setAllOrders([]);
          setFilteredOrders([]);
        }
      } catch (error) {
        console.error('Error fetching orders:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, [dateRange.startDate, dateRange.endDate]);

  const refreshOrders = () => {
    setLoading(true);
    const dbRef = firebase.database().ref('orders');
    
    dbRef.once('value')
      .then((snapshot) => {
        const ordersData = snapshot.val();
        if (ordersData) {
          const orders = Object.entries(ordersData).map(([key, value]) => ({
            id: key,
            ...value,
            isDerivative: !!value.derivedFrom
          }));

          // Filter orders within date range
          const filteredOrders = orders.filter(order => {
            const [orderDay, orderMonth, orderYear] = (order.OrderDate || '').split('-').map(Number);
            const orderDate = new Date(orderYear, orderMonth - 1, orderDay);
            
            const [startDay, startMonth, startYear] = dateRange.startDate.split('-').map(Number);
            const [endDay, endMonth, endYear] = dateRange.endDate.split('-').map(Number);
            
            const startDate = new Date(startYear, startMonth - 1, startDay);
            const endDate = new Date(endYear, endMonth - 1, endDay);

            return orderDate >= startDate && orderDate <= endDate;
          });

          setAllOrders(filteredOrders);
          setFilteredOrders(filteredOrders);
        } else {
          setAllOrders([]);
          setFilteredOrders([]);
        }
      })
      .catch((error) => {
        console.error('Error fetching orders:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // Apply client-side filters
  useEffect(() => {
    let updatedOrders = [...allOrders];

    // Apply all filters
    updatedOrders = updatedOrders.filter(order => {
      if (selectedVendor !== 'all' && order.OrderVendor !== selectedVendor) return false;
      if (filterStatus !== 'all') {
        if (filterStatus === 'Invoiced') {
          if (order.Status !== 'Invoiced' && order.Status !== 'Archived') return false;
        } else {
          if (order.Status !== filterStatus) return false;
        }
      }
      if (searchTerm && !order.OrderID?.includes(searchTerm)) return false;
      if (buyerSearchTerm && !order.Buyer?.toLowerCase().includes(buyerSearchTerm.toLowerCase())) return false;
      if (commentSearchTerm && !order.Comment?.toLowerCase().includes(commentSearchTerm.toLowerCase())) return false;
      if (invoiceSearchTerm) {
        const invoiceNumber = order.invoiceNumber?.toLowerCase() || '';
        const searchLower = invoiceSearchTerm.toLowerCase();
        if (!invoiceNumber.includes(searchLower)) return false;
      }
      return true;
    });

    // Sort orders
    updatedOrders.sort((a, b) => {
      if (!a.OrderDate || !b.OrderDate) return 0;

      if (sortBy === 'date') {
        return parseDate(b.OrderDate) - parseDate(a.OrderDate);
      } else if (sortBy === 'vendor') {
        return a.OrderVendor.localeCompare(b.OrderVendor);
      } else if (sortBy === 'buyer') {
        return a.Buyer.localeCompare(b.Buyer);
      }
      return 0;
    });

    setFilteredOrders(updatedOrders);
  }, [searchTerm, buyerSearchTerm, commentSearchTerm, invoiceSearchTerm, filterStatus, sortBy, selectedVendor, allOrders]);

  return (
    <div className="archive-container">
      <h1>Архива</h1>
      <div className="archive-controls">
        <div className="search-controls">
          <input
            type="text"
            placeholder="Бр. на нарачка..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <input
            type="text"
            placeholder="Купец..."
            value={buyerSearchTerm}
            onChange={(e) => setBuyerSearchTerm(e.target.value)}
          />
          <input
            type="text"
            placeholder="Фактура..."
            value={invoiceSearchTerm}
            onChange={(e) => setInvoiceSearchTerm(e.target.value)}
          />
          <input
            type="text"
            placeholder="Коментар..."
            value={commentSearchTerm}
            onChange={(e) => setCommentSearchTerm(e.target.value)}
          />
        </div>
        <div className="filter-controls">
          <select
            value={selectedVendor}
            onChange={(e) => setSelectedVendor(e.target.value)}
          >
            <option value="all">Сите Производители</option>
            <option value="Donaldson">Donaldson</option>
            <option value="Euroventilatori">Euroventilatori</option>
          </select>
          <select
            value={filterStatus}
            onChange={(e) => setFilterStatus(e.target.value)}
          >
            <option value="all">Сите Статуси</option>
            <option value="Processing">Во Обработка</option>
            <option value="Invoiced">Фактурирани</option>
            <option value="Archived">Архивирани</option>
          </select>
          <select
            value={sortBy}
            onChange={(e) => setSortBy(e.target.value)}
          >
            <option value="date">подреди по датум</option>
            <option value="vendor">Подреди по производител</option>
            <option value="buyer">Подреди по купец</option>
          </select>
        </div>
        <div className="date-range-controls">
          <div className="date-input-group">
            <label>Од:</label>
            <input
              type="date"
              value={dateRange.startDate ? convertToHTMLDate(dateRange.startDate) : ''}
              onChange={(e) => handleDateChange('startDate', e.target.value)}
            />
          </div>
          <div className="date-input-group">
            <label>До:</label>
            <input
              type="date"
              value={dateRange.endDate ? convertToHTMLDate(dateRange.endDate) : ''}
              onChange={(e) => handleDateChange('endDate', e.target.value)}
            />
          </div>
        </div>
      </div>
      {loading && <div className="loading">Loading...</div>}
      <OrdersTableArchive
        orders={filteredOrders}
        userRole={userRole}
        onOrderDeleted={refreshOrders}
      />
    </div>
  );
}

export default Archive;
