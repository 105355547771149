import React from 'react';

const LoadingScreen = () => {
  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#f8f9fa',
      zIndex: 9999
    }}>
      <div style={{
        width: '100px',
        height: '100px',
        border: '10px solid #f3f3f3',
        borderTop: '10px solid #3498db',
        borderRadius: '50%',
        animation: 'spin 1s linear infinite',
        marginBottom: '20px'
      }} />
      <style>
        {`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}
      </style>
      <p style={{
        color: '#666',
        fontSize: '16px',
        fontWeight: 800
      }}>Вчитување...</p>
    </div>
  );
};

export default LoadingScreen;
