import React, { useState, useEffect } from 'react';
import { ref, get, set, push, remove } from 'firebase/database';
import { db2 } from '../firebase';
import "../css/CompanyList.css";
import AddCompanyModal from './AddCompanyModal';
import EditCompanyModal from './EditCompanyModal';
import Select from 'react-select';
import CompanyDetail from './CompanyDetail';
import countryFlagEmoji from 'country-flag-emoji';
import ConfirmModal from './ConfirmModal';

const CompanyList = ({ showAddModal, onCloseModal }) => {
  const [companies, setCompanies] = useState([]);
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedIndustry, setSelectedIndustry] = useState(null);
  const [showEditCompanyModal, setShowEditCompanyModal] = useState(false);
  const [industries, setIndustries] = useState([]);
  const [cities, setCities] = useState([]);
  const [countries, setCountries] = useState([]);
  const [filteredCities, setFilteredCities] = useState([]);
  const [isFilterView, setIsFilterView] = useState(false);
  const [selectedFilterType, setSelectedFilterType] = useState('industries');
  const [newFilterItem, setNewFilterItem] = useState('');
  const [selectedCountryForCity, setSelectedCountryForCity] = useState(null);
  const [countryEmoji, setCountryEmoji] = useState('');
  const [filterItems, setFilterItems] = useState([]);
  const [deleteConfirmation, setDeleteConfirmation] = useState({
    isOpen: false,
    item: null
  });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [localShowAddModal, setLocalShowAddModal] = useState(false);

  const countryEmojis = countryFlagEmoji.list.map(country => ({
    value: country.emoji,
    label: `${country.emoji} ${country.name}`,
    code: country.code
  }));

  const filterTypes = [
    { id: 'industries', label: 'Индустрија ', dbPath: 'industrija' },
    { id: 'countries', label: 'Држава ', dbPath: 'countries' },
    { id: 'cities', label: 'Град ', dbPath: 'cities' }
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const citiesRef = ref(db2, 'cities');
        const citiesSnapshot = await get(citiesRef);
        if (citiesSnapshot.exists()) {
          const citiesData = Object.values(citiesSnapshot.val()).map(city => ({
            value: city.naziv,
            label: city.naziv,
            drzava: city.drzava
          }));
          setCities(citiesData);
        }

        const countriesRef = ref(db2, 'countries');
        const countriesSnapshot = await get(countriesRef);
        if (countriesSnapshot.exists()) {
          setCountries(Object.values(countriesSnapshot.val()).map(country => ({
            value: country.naziv,
            label: country.naziv,
            displayName: country.emoji ? `${country.emoji} ${country.naziv}` : country.naziv
          })));
        }

        const industriesRef = ref(db2, 'industrija');
        const industriesSnapshot = await get(industriesRef);
        if (industriesSnapshot.exists()) {
          setIndustries(Object.values(industriesSnapshot.val()).map(industry => ({
            value: industry.naziv,
            label: industry.naziv
          })));
        }
      } catch (error) {
        console.error("Проблем при повлекување податоци:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        let queryRef = ref(db2, 'companies');
        const snapshot = await get(queryRef);
        if (snapshot.exists()) {
          let companyList = Object.values(snapshot.val());

          if (selectedCity) {
            companyList = companyList.filter(company => company.grad_ime === selectedCity.value);
          }
          if (selectedCountry) {
            companyList = companyList.filter(company => company.drzava_ime === selectedCountry.value);
          }
          if (selectedIndustry) {
            companyList = companyList.filter(company => company.industrija_naziv === selectedIndustry.value);
          }
          if (searchQuery) {
            companyList = companyList.filter(company => company.ime.toLowerCase().includes(searchQuery.toLowerCase()));
          }

          setCompanies(companyList);
          setFilteredCompanies(companyList.slice(0, 50)); // Limit to 50 results

          // Automatically select the first company if there are results
          if (companyList.length > 0) {
            setSelectedCompany(companyList[0]);
          } else {
            setSelectedCompany(null); // Clear selection if no results
          }
        } else {
          console.log('No data available');
        }
      } catch (error) {
        console.error('Error fetching companies:', error);
      }
    };

    fetchCompanies();
  }, [selectedCity, selectedCountry, selectedIndustry, searchQuery]);

  useEffect(() => {
    if (selectedCountry) {
      const citiesForCountry = cities.filter(city => city.drzava === selectedCountry.value);
      console.log('Filtered cities:', citiesForCountry); // Debug log
      setFilteredCities(citiesForCountry);
    } else {
      setFilteredCities([]);
      setSelectedCity(null);
    }
  }, [selectedCountry, cities]);

  useEffect(() => {
    const loadFilters = async () => {
      try {
        const dbPath = filterTypes.find(type => type.id === selectedFilterType)?.dbPath;
        if (!dbPath) return;

        const dbRef = ref(db2, dbPath);
        const snapshot = await get(dbRef);
        if (snapshot.exists()) {
          const items = Object.entries(snapshot.val()).map(([key, item]) => ({
            id: key,  // Store the Firebase key
            name: item.naziv,
            type: selectedFilterType,
            ...(selectedFilterType === 'cities' ? { country: item.drzava } : {}),
            ...(selectedFilterType === 'countries' && item.emoji ? { emoji: item.emoji } : {})
          }));
          setFilterItems(items);
        } else {
          setFilterItems([]);
        }
      } catch (error) {
        console.error('Error loading filters:', error);
        setFilterItems([]);
      }
    };

    loadFilters();
  }, [selectedFilterType]);

  useEffect(() => {
    if (showAddModal) {
      setLocalShowAddModal(true);
    }
  }, [showAddModal]);

  const handleCloseAddModal = () => {
    setLocalShowAddModal(false);
    onCloseModal();
  };

  const handleCompanyClick = (company) => {
    setSelectedCompany(company);
  };

  const handleAddCompany = (newCompany) => {
    const newCompanyRef = push(ref(db2, 'companies')); // Generates a unique key and references it

    const newCompanyWithId = {
      ...newCompany,
      id: newCompanyRef.key, // Assign the generated key to the company
    };

    set(newCompanyRef, newCompanyWithId)
      .then(() => {
        const newCompaniesList = [...companies, newCompanyWithId];
        setCompanies(newCompaniesList);
        setFilteredCompanies(newCompaniesList.slice(0, 50));
        console.log('Company added successfully');
        setSearchQuery(newCompany.ime);
      })
      .catch(error => console.error('Error adding company:', error));
  };

  const handleEditCompany = (updatedCompany) => {
    const updatedCompaniesList = companies.map(company =>
      company.id === updatedCompany.id ? updatedCompany : company
    );
    setCompanies(updatedCompaniesList);
    setFilteredCompanies(updatedCompaniesList.slice(0, 50));
    const companyRef = ref(db2, `companies/${updatedCompany.id}`);
    set(companyRef, updatedCompany)
      .then(() => console.log('Company updated successfully'))
      .catch(error => console.error('Error updating company:', error));
  };

  const handleCountryChange = (selected) => {
    setSelectedCountry(selected);
    setSelectedCity(null); // Reset city when country changes
  };

  const handleFilterTypeSelect = (type) => {
    setSelectedFilterType(type);
    setSelectedCountryForCity(null);
    setNewFilterItem('');
    setCountryEmoji('');
    setFilterItems([]); // Clear previous items while loading new ones
  };

  const handleAddFilter = async () => {
    if (!newFilterItem.trim()) return;

    try {
      const dbPath = filterTypes.find(type => type.id === selectedFilterType)?.dbPath;
      if (!dbPath) return;

      const dbRef = ref(db2, dbPath);
      const newItem = {
        naziv: newFilterItem,
        ...(selectedFilterType === 'cities' && selectedCountryForCity 
          ? { drzava: selectedCountryForCity.value } 
          : {}),
        ...(selectedFilterType === 'countries' && countryEmoji 
          ? { naziv: `${newFilterItem} ${countryEmoji}` } 
          : {})
      };

      const newItemRef = push(dbRef);
      await set(newItemRef, newItem);
      
      setNewFilterItem('');
      if (selectedFilterType === 'countries') {
        setCountryEmoji('');
      }
    } catch (error) {
      console.error('Проблем при додвање:', error);
    }
  };

  const handleDeleteFilter = async (item) => {
    try {
      const dbPath = filterTypes.find(type => type.id === selectedFilterType)?.dbPath;
      if (!dbPath) return;

      const itemRef = ref(db2, `${dbPath}/${item.id}`);
      await remove(itemRef);
      
      // Update local state
      setFilterItems(prev => prev.filter(i => i.id !== item.id));
      setDeleteConfirmation({ isOpen: false, item: null });
    } catch (error) {
      console.error('Проблем при бришење:', error);
    }
  };

  const showDeleteConfirmation = (item) => {
    setDeleteConfirmation({
      isOpen: true,
      item: item
    });
  };

  const handleDeleteCompany = async () => {
    try {
      const companyRef = ref(db2, `companies/${selectedCompany.id}`);
      await remove(companyRef);
      
      const updatedCompanies = companies.filter(company => company.id !== selectedCompany.id);
      setCompanies(updatedCompanies);
      setFilteredCompanies(updatedCompanies);
      setSelectedCompany(null);
      setShowDeleteModal(false);
    } catch (error) {
      console.error('Проблем при бришење:', error);
    }
  };

  const renderFilterForm = () => {
    return (
      <div className="filter-add-form">
        {selectedFilterType === 'cities' && (
          <div className="country-select-wrapper">
            <Select
              value={selectedCountryForCity}
              onChange={setSelectedCountryForCity}
              options={countries.map(country => ({
                value: country.value,
                label: country.label
              }))}
              placeholder="Избери Држава"
              className="country-select"
            />
          </div>
        )}
        <div className="filter-input-group">
          <input
            type="text"
            value={newFilterItem}
            onChange={(e) => setNewFilterItem(e.target.value)}
            placeholder={`Внесете ${filterTypes.find(type => type.id === selectedFilterType)?.label.trim()}`}
            className="filter-input"
          />
          {selectedFilterType === 'countries' && (
            <Select
              value={countryEmojis.find(emoji => emoji.value === countryEmoji)}
              onChange={(selected) => setCountryEmoji(selected ? selected.value : '')}
              options={countryEmojis}
              placeholder="🏳️"
              className="emoji-select"
            />
          )}
          <button 
            onClick={handleAddFilter}
            className="filter-button add-button"
            disabled={!newFilterItem || (selectedFilterType === 'cities' && !selectedCountryForCity)}
          >
            Додај
          </button>
        </div>
      </div>
    );
  };

  const renderFilterItems = () => {
    if (!filterItems.length) {
      return <div className="no-items">No {selectedFilterType} added yet</div>;
    }

    const items = selectedFilterType === 'cities' && selectedCountryForCity
      ? filterItems.filter(item => item.country === selectedCountryForCity.value)
      : filterItems.filter(item => {
          if (selectedFilterType === 'cities') {
            return !selectedCountryForCity;
          }
          return true;
        });

    if (items.length === 0) {
      return <div className="no-items">
        {selectedFilterType === 'cities' && selectedCountryForCity 
          ? `No cities added for ${selectedCountryForCity.label}`
          : `No ${selectedFilterType} added yet`}
      </div>;
    }

    return items.map(item => (
      <div key={item.id} className="filter-item">
        <span>
          {item.name}
          {selectedFilterType === 'countries' && item.emoji ? ` ${item.emoji}` : ''}
        </span>
        <div className="filter-actions">
          <button 
            className="delete-button" 
            onClick={() => showDeleteConfirmation(item)}
          >
            Delete
          </button>
        </div>
      </div>
    ));
  };

  const renderFilterContent = () => {
    return (
      <div className="filter-management">
        <div className="filter-type-list">
          {filterTypes.map(type => (
            <button
              key={type.id}
              className={`filter-type-button ${selectedFilterType === type.id ? 'selected' : ''}`}
              onClick={() => handleFilterTypeSelect(type.id)}
            >
              {type.label}
            </button>
          ))}
        </div>

        <div className="filter-details">
          {renderFilterForm()}
          <div className="filter-items">
            {renderFilterItems()}
          </div>
        </div>
      </div>
    );
  };

  const CompanyDetail = ({ title, content }) => (
    <div>
      <strong>{title}: </strong>
      {content}
    </div>
  );

  return (
    <div className="company-page">
      <div className="filters-section">
        <h3>Филтри</h3>
        <div className="search-section">
          <input
            type="text"
            placeholder="Пребарувај фирма..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="search-input"
          />
        </div>

        <div className="filter-section">
          <Select
            value={selectedCountry}
            onChange={handleCountryChange}
            options={countries}
            placeholder="избери држава"
            isClearable
          />
          {selectedCountry && (
            <Select
              value={selectedCity}
              onChange={(selected) => setSelectedCity(selected)}
              options={filteredCities}
              placeholder="избери град"
              isClearable
            />
          )}
          <Select
            value={selectedIndustry}
            onChange={(selected) => setSelectedIndustry(selected)}
            options={industries}
            placeholder="избери индустрија"
            isClearable
          />
        </div>

        <div className="actions-section">
          <button onClick={() => {
            setLocalShowAddModal(true);
            onCloseModal(true);
          }}>Додај Фирма</button>
          <button onClick={() => setIsFilterView(!isFilterView)}>
            {isFilterView ? 'Назад кон фирми' : 'Уреди филтри'}
          </button>
        </div>
      </div>

      {!isFilterView ? (
        <>
          <div className="companies-list">
            <div className="companies-grid">
              {filteredCompanies.map(company => (
                <div
                  key={company.id}
                  className={`company-card ${selectedCompany?.id === company.id ? 'selected' : ''}`}
                  onClick={() => handleCompanyClick(company)}
                >
                  <h3>{company.ime}</h3>
                  <p>{company.grad_ime}, {company.drzava_ime}</p>
                  <p>{company.industrija_naziv}</p>
                </div>
              ))}
            </div>
          </div>

          <div className="company-details">
            {selectedCompany ? (
              <>
                <h3>{selectedCompany.ime}</h3>
                <CompanyDetail title="Контакт" content={selectedCompany.kontakt} />
                <CompanyDetail title="Адреса" content={selectedCompany.adresa} />
                <CompanyDetail title="Веб страна" content={<a href={selectedCompany.web} target="_blank" rel="noopener noreferrer">{selectedCompany.web}</a>} />
                <CompanyDetail title="Соработка" content={selectedCompany.sorabotka} />
                <CompanyDetail title="Опрема" content={selectedCompany.oprema} />
                <CompanyDetail title="Држава" content={selectedCompany.drzava_ime} />
                <CompanyDetail title="Град" content={selectedCompany.grad_ime} />
                <CompanyDetail title="Индустрија" content={selectedCompany.industrija_naziv} />
                <div className="company-actions">
                  <button className="edit-button" onClick={() => setShowEditCompanyModal(true)}>
                    Промени инфромации 
                  </button>
                  <button className="delete-button" onClick={() => setShowDeleteModal(true)}>
                    Избриши
                  </button>
                </div>
              </>
            ) : (
              <div className="no-selection">
                <p>Одберете фирма</p>
              </div>
            )}
          </div>
        </>
      ) : (
        renderFilterContent()
      )}

      {(showAddModal || localShowAddModal) && (
        <AddCompanyModal 
          onClose={handleCloseAddModal}
          onAdd={handleAddCompany} 
          cities={cities} 
          industries={industries} 
          countries={countries}
        />
      )}
      {showEditCompanyModal && (
        <EditCompanyModal 
          onClose={() => setShowEditCompanyModal(false)} 
          onEdit={handleEditCompany} 
          company={selectedCompany}
          cities={cities}
          industries={industries}
          countries={countries} 
        />
      )}
      {showDeleteModal && (
        <ConfirmModal
          isOpen={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          onConfirm={handleDeleteCompany}
          title="Избриши Фирма"
          message={`Дали сте сигурни дека сакате да избришете фирмата ${selectedCompany?.ime}? Овие податоци не може да бидат вратени.`}
          confirmText="Избриши"
          cancelText="Откажи"
        />
      )}
      <ConfirmModal
        isOpen={deleteConfirmation.isOpen}
        onClose={() => setDeleteConfirmation({ isOpen: false, item: null })}
        onConfirm={() => handleDeleteFilter(deleteConfirmation.item)}
        title="Избриши Филтер"
        message={`Дали сте сигурни дека сакате да избришете филтерот "${deleteConfirmation.item?.name}"?`}
      />
    </div>
  );
};

export default CompanyList;
