// Modal.js
import React, {useState} from 'react';
import '../css/invoiceEditModal.css'; // Create styles for modal as needed

const ModalCompamny = ({ title, value, value2, onSave, onClose }) => {
  const [inputValue, setInputValue] = useState(value);
  const [inputValue2, setInputValue2] = useState(value2);

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };
const handleChange2 = (e) => {
  setInputValue2(e.target.value);
}
  const handleSubmit = () => {
    onSave(inputValue, inputValue2);
  };

  return (
    <div className="modal-overlay-invoice">
      <div className="modal-content-invoice">
        <h2>{title}</h2>
        <textarea
          value={inputValue}
          onChange={handleChange}
          rows={1} // Set the initial number of rows
        />
                <textarea
          value={inputValue2}
          onChange={handleChange2}
          rows={3} // Set the initial number of rows
        />
        <button onClick={handleSubmit}>Save</button>
        <button onClick={onClose}>Cancel</button>
      </div>
    </div>
  );
};

export default ModalCompamny;

