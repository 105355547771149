import React, { useState, useEffect } from 'react';
import { useOrderStep } from '../context/OrderStepContext';
import { BasicInfoStep } from '../components/OrderSteps/BasicInfoStep';
import { CompanyDetailsStep } from '../components/OrderSteps/CompanyDetailsStep';
import { ItemsStep } from '../components/OrderSteps/ItemsStep';
import { DocumentsStep } from '../components/OrderSteps/DocumentsStep';
import { getDatabase, ref, set, get, update } from 'firebase/database';
import { v4 as uuidv4 } from 'uuid';
import '../css/orderSteps.css';

export default function OrderForm({ manufacturer, companies, userRole, activeOrders }) {
  const { state, dispatch, hideForm } = useOrderStep();
  const [submitting, setSubmitting] = useState(false);
  const [exitPrompt, setExitPrompt] = useState(false);

  const handleExit = () => {
    const hasChanges = Object.values(state.stepValidation).some(value => value) ||
                      Object.values(state.orderData).some(value => 
                        value && value !== '' && value !== null
                      );
    
    if (hasChanges) {
      setExitPrompt(true);
    } else {
      hideForm();
    }
  };

  const confirmExit = () => {
    hideForm();
    setExitPrompt(false);
  };

  const formatDateToString = (date) => {
    if (!date) return '';
    
    // If it's a Date object, format it to DD-MM-YYYY
    if (date instanceof Date) {
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    }
    
    // If it's already in DD-MM-YYYY format, return as is
    if (typeof date === 'string') {
      const ddmmyyyyRegex = /^(\d{2})-(\d{2})-(\d{4})$/;
      if (ddmmyyyyRegex.test(date)) {
        return date;
      }
      
      // Try to parse the date string
      const parts = date.split(/[-/]/);
      if (parts.length === 3) {
        // Check if the first part is a year (YYYY-MM-DD format)
        if (parts[0].length === 4) {
          const [year, month, day] = parts;
          return `${day.padStart(2, '0')}-${month.padStart(2, '0')}-${year}`;
        }
        // Assume MM/DD/YYYY format
        const [month, day, year] = parts;
        return `${day.padStart(2, '0')}-${month.padStart(2, '0')}-${year}`;
      }
    }
    
    // Try to parse as date string if all else fails
    const parsedDate = new Date(date);
    if (!isNaN(parsedDate.getTime())) {
      const day = parsedDate.getDate().toString().padStart(2, '0');
      const month = (parsedDate.getMonth() + 1).toString().padStart(2, '0');
      const year = parsedDate.getFullYear();
      return `${day}-${month}-${year}`;
    }
    
    return '';
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (submitting) return;

    setSubmitting(true);
    const db = getDatabase();
    
    try {
      // Prepare order data
      const orderData = {
        ...state.orderData,
        id: state.orderData.id || uuidv4(),
        CreatedAt: formatDateToString(new Date()),
        UpdatedAt: formatDateToString(new Date())
      };

      // Ensure OrderDate is set
      if (!orderData.OrderDate) {
        orderData.OrderDate = formatDateToString(new Date());
      }

      // Format all dates to DD-MM-YYYY
      if (typeof orderData.OrderDate === 'object') {
        orderData.OrderDate = formatDateToString(orderData.OrderDate);
      }
      orderData.DateOfDelivery = formatDateToString(orderData.DateOfDelivery);
      orderData.DateOfPayment = formatDateToString(orderData.DateOfPayment);

      // If this is a new order, increment the counter
      if (orderData._counterInfo) {
        const updates = {
          [`invoices/${orderData._counterInfo.vendor}/${orderData._counterInfo.isEU ? 'CurrentInvoiceEU' : 'CurrentInvoiceMK'}`]: 
            (orderData._counterInfo.currentCounter + 1).toString()
        };
        await update(ref(db), updates);
        
        // Remove the counter info before saving the order
        delete orderData._counterInfo;
      }

      // If this is a derivative order, ensure it has its own UUID
      if (orderData.derivedFrom) {
        const newId = uuidv4();
        
        // Get the base order
        const baseOrderRef = ref(db, `orders/${orderData.derivedFrom}`);
        const baseOrderSnapshot = await get(baseOrderRef);
        
        if (baseOrderSnapshot.exists()) {
          const baseOrder = baseOrderSnapshot.val();
          
          // Update base order's derivative orders list
          const derivativeOrders = baseOrder.derivativeOrders || [];
          derivativeOrders.push({
            id: newId,
            OrderID: orderData.OrderID,
            CreatedAt: orderData.CreatedAt
          });
          
          // Update the base order
          await update(baseOrderRef, {
            derivativeOrders,
            UpdatedAt: formatDateToString(new Date())
          });
        }
        
        // Set the new ID for the derivative order
        orderData.id = newId;
      }

      // Initialize derivativeOrders array if this is a new order
      if (!orderData.derivedFrom && !orderData.derivativeOrders) {
        orderData.derivativeOrders = [];
      }

      // Save to Firebase
      const orderRef = ref(db, `orders/${orderData.id}`);
      await set(orderRef, orderData);
      
      console.log('Order saved successfully:', orderData);
      hideForm();
    } catch (error) {
      console.error('Error submitting order:', error);
      alert('Error saving order. Please try again.');
    } finally {
      setSubmitting(false);
    }
  };

  const renderCurrentStep = () => {
    switch (state.currentStep) {
      case 1:
        return <BasicInfoStep manufacturer={manufacturer} activeOrders={activeOrders} />;
      case 2:
        return <CompanyDetailsStep companies={companies} userRole={userRole} />;
      case 3:
        return <ItemsStep />;
      case 4:
        return <DocumentsStep />;
      default:
        return null;
    }
  };

  useEffect(() => {
    const handleEscapeKey = (e) => {
      if (e.key === 'Escape') {
        handleExit();
      }
    };

    window.addEventListener('keydown', handleEscapeKey);
    return () => window.removeEventListener('keydown', handleEscapeKey);
  }, [state.orderData]);

  useEffect(() => {
    if (!state.orderData.OrderDate) {
      dispatch({
        type: 'UPDATE_ORDER_DATA',
        payload: {
          OrderDate: formatDateToString(new Date())
        }
      });
    }
  }, []);

  if (!state.isVisible) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content order-form-modal">
        <div className="modal-header">
          <h2>Нова Нарачка - {state.orderData.OrderVendor}</h2>
          <button
            type="button"
            className="close-button"
            onClick={handleExit}
            aria-label="Close"
          >
            ×
          </button>
        </div>

        <form onSubmit={handleSubmit}>
          <div className="step-progress">
            {Array.from({ length: state.totalSteps }, (_, i) => (
              <div
                key={i + 1}
                className={`step-indicator ${
                  state.currentStep === i + 1 ? 'active' : ''
                } ${state.stepValidation[i + 1] ? 'validated' : ''}`}
              >
                Чекор {i + 1}
              </div>
            ))}
          </div>

          {renderCurrentStep()}
        </form>

        {exitPrompt && (
          <div className="modal-overlay">
            <div className="modal-content confirmation-modal">
              <h3>Незачувани промени</h3>
              <p>Имате незачувани промени. Дали сте ссигурни дека сакате да излезите без да ја довршете нарачката?</p>
              <div className="modal-actions">
                <button
                  type="button"
                  className="button secondary-button"
                  onClick={() => setExitPrompt(false)}
                >
                  Продолжи со оваа нарачка
                </button>
                <button
                  type="button"
                  className="button danger-button"
                  onClick={confirmExit}
                >
                  Излези и избриши
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}