/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Select from 'react-select';
import "../css/CompanyList.css";

const AddCompanyModal = ({ industries, countries, cities, onAdd, onClose }) => {
  const [newCompany, setNewCompany] = useState({
    ime: '',
    kontakt: '',
    adresa: '',
    web: '',
    interes: '',
    sorabotka: '',
    oprema: '',
    google_location: '',
    drzava_ime: '',
    grad_ime: '',
    industrija_naziv: '',
    region_ime: ''
  });

  const [filteredCities, setFilteredCities] = useState([]);

  // Handle text input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewCompany(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  // Handle select input change for dropdowns
  const handleSelectChange = (selectedOption, { name }) => {
    setNewCompany(prevState => ({
      ...prevState,
      [name]: selectedOption ? selectedOption.value : ''
    }));

    // Filter cities based on selected country
    if (name === 'drzava_ime') {
      // Reset the city selection when country changes
      setNewCompany(prevState => ({
        ...prevState,
        grad_ime: ''
      }));
      
      // Filter cities based on selected country
      const filtered = cities.filter(city => city.drzava === selectedOption.value);
      setFilteredCities(filtered.map(city => ({
        value: city.value,
        label: city.label
      })));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onAdd(newCompany);
    onClose();
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close" onClick={onClose}>&times;</span>
        <h2>Додај Фирма</h2>
        <form onSubmit={handleSubmit} className="form-container">
          <div className="form-group">
            <label className="label" htmlFor="ime">Име:</label>
            <input
              className="input"
              type="text"
              id="ime"
              name="ime"
              value={newCompany.ime}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label className="label" htmlFor="kontakt">Контакт:</label>
            <textarea
              className="input"
              id="kontakt"
              name="kontakt"
              value={newCompany.kontakt}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label className="label" htmlFor="adresa">Адреса:</label>
            <textarea
              className="input"
              id="adresa"
              name="adresa"
              value={newCompany.adresa}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label className="label" htmlFor="web">Web страна:</label>
            <input
              className="input"
              type="text"
              id="web"
              name="web"
              value={newCompany.web}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label className="label" htmlFor="sorabotka">Соработка:</label>
            <textarea
              className="input"
              id="sorabotka"
              name="sorabotka"
              value={newCompany.sorabotka}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label className="label" htmlFor="oprema">Опрема:</label>
            <textarea
              className="input"
              id="oprema"
              name="oprema"
              value={newCompany.oprema}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <label className="label" htmlFor="drzava_ime">Држава:</label>
            <Select
              className="input-dropdown"
              id="drzava_ime"
              name="drzava_ime"
              options={countries}
              value={countries.find(option => option.value === newCompany.drzava_ime)}
              onChange={handleSelectChange}
              placeholder="Одбери држава"
              required
            />
          </div>
          <div className="form-group">
            <label className="label" htmlFor="grad_ime">Град:</label>
            <Select
              className="input-dropdown"
              id="grad_ime"
              name="grad_ime"
              options={filteredCities}
              value={filteredCities.find(option => option.value === newCompany.grad_ime)}
              onChange={handleSelectChange}
              placeholder="Одбери град"
              required
            />
          </div>
          <div className="form-group">
            <label className="label" htmlFor="industrija_naziv">Индустрија:</label>
            <Select
              className="input-dropdown"
              id="industrija_naziv"
              name="industrija_naziv"
              options={industries}
              value={industries.find(option => option.value === newCompany.industrija_naziv)}
              onChange={handleSelectChange}
              placeholder="Одбери индустрија"
              required
            />
          </div>
          <button type="submit" className="submit-button">Додај</button>
        </form>
      </div>
    </div>
  );
};

export default AddCompanyModal;
