import React, { useEffect, useState } from 'react';
import { StepBase } from './StepBase';
import { useOrderStep } from '../../context/OrderStepContext';
import firebase from '../../firebaseOLD';

export function DocumentsStep() {
  const { state, updateOrderData, setStepValidation } = useOrderStep();
  const [uploading, setUploading] = useState(false);
  const [documentName, setDocumentName] = useState('');

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    setUploading(true);

    try {
      const storageRef = firebase.storage().ref();
      const docRef = storageRef.child(`documents/${state.orderData.OrderID}/${file.name}`);

      // Upload file to Firebase Storage
      const snapshot = await docRef.put(file);
      const downloadURL = await snapshot.ref.getDownloadURL();

      // Store the document name for display purposes
      setDocumentName(file.name);

      // Update order data with the download URL at the root level
      updateOrderData({
        OtherLink: downloadURL
      });
    } catch (error) {
      console.error('Error uploading document:', error);
      alert('Error uploading file. Please try again.');
    } finally {
      setUploading(false);
    }
  };

  const handleRemoveFile = () => {
    // Clear the document URL from the root level
    updateOrderData({
      OtherLink: ''
    });
    
    // Clear the document name from local state
    setDocumentName('');
  };

  // Initialize document name from existing URL if any
  useEffect(() => {
    if (state.orderData.OtherLink) {
      const otherName = state.orderData.OtherLink.split('/').pop().split('?')[0];
      setDocumentName(decodeURIComponent(otherName));
    }
  }, [state.orderData.OtherLink]);

  // Always valid since document is optional
  useEffect(() => {
    setStepValidation(4, true);
  }, [setStepValidation]);

  return (
    <StepBase stepNumber={4} title="Прикачи документ">
      <div className='step-sub-content'>
      <div className="documents-section">
        <div className="notice-message">
          <p>Додадете документ:</p>
          <p>Не е потребно доколку додадовте предмети во претходниот чекор</p>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label className="label">Документ:</label>
            <div className="file-upload">
              <input
                type="file"
                onChange={handleFileUpload}
                disabled={uploading}
              />
              {uploading && <span>Се Прикачува...</span>}
              {state.orderData.OtherLink && documentName && (
                <div className="file-info">
                  <a href={state.orderData.OtherLink} target="_blank" rel="noopener noreferrer">
                    {documentName}
                  </a>
                  <button
                    type="button"
                    className="button danger-button"
                    onClick={handleRemoveFile}
                  >
                    Одстрани
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      </div>
    </StepBase>
  );
}
