import React, { createContext, useContext, useReducer } from 'react';

const OrderStepContext = createContext();

const initialState = {
  isVisible: false,
  currentStep: 1,
  totalSteps: 4,
  orderData: {
    Jurisdiction: '',
    OrderID: '',
    OrderVendor: '',
    Buyer: '',
    OriginationNumber: '',
    DateOfDelivery: null,
    DateOfPayment: null,
    Comment: '',
    Advance: '0.00',
    Username: '',
    Status: 'Processing',
    itemInputType: 'file',
    orderItems: [], 
    currency: 'MK-MKD',
  },
  stepValidation: {
    1: false, // Basic Info
    2: false, // Company Details
    3: false, // Items
    4: false  // Documents
  }
};

function orderReducer(state, action) {
  switch (action.type) {
    case 'UPDATE_ORDER_DATA':
      return {
        ...state,
        orderData: {
          ...state.orderData,
          ...action.payload
        }
      };
    case 'SET_STEP':
      return {
        ...state,
        currentStep: action.payload,
        orderData: {
          ...state.orderData
        }
      };
    case 'UPDATE_VALIDATION':
      return {
        ...state,
        stepValidation: {
          ...state.stepValidation,
          [action.payload.step]: action.payload.isValid
        }
      };
    case 'ADD_ITEM':
      return {
        ...state,
        orderData: {
          ...state.orderData,
          orderItems: [...state.orderData.orderItems, action.payload]
        }
      };
    case 'UPDATE_ITEM':
      return {
        ...state,
        orderData: {
          ...state.orderData,
          orderItems: state.orderData.orderItems.map((item, index) =>
            index === action.payload.index ? action.payload.item : item
          )
        }
      };
    case 'REMOVE_ITEM':
      return {
        ...state,
        orderData: {
          ...state.orderData,
          orderItems: state.orderData.orderItems.filter((_, index) => index !== action.payload)
        }
      };
    case 'SET_DOCUMENT':
      return {
        ...state,
        orderData: {
          ...state.orderData,
          documents: {
            ...state.orderData.documents,
            [action.payload.type]: action.payload.file
          }
        }
      };
    case 'SHOW_FORM':
      return {
        ...state,
        isVisible: true
      };
    case 'HIDE_FORM':
      return {
        ...initialState,
        isVisible: false
      };
    case 'INIT_ORDER':
      return {
        ...state,
        isVisible: true,
        orderData: {
          ...state.orderData,
          OrderID: action.payload.orderId,
          Username: action.payload.username,
          OrderVendor: action.payload.manufacturer
        }
      };
    case 'RESET_ORDER':
      return initialState;
    case 'CLEAR_ITEMS':
      return {
        ...state,
        orderData: {
          ...state.orderData,
          orderItems: []
        }
      };
    default:
      return state;
  }
}

export function OrderStepProvider({ children }) {
  const [state, dispatch] = useReducer(orderReducer, initialState);

  const value = {
    state,
    dispatch,
    nextStep: () => {
      if (state.currentStep < state.totalSteps) {
        dispatch({ type: 'SET_STEP', payload: state.currentStep + 1 });
      }
    },
    previousStep: () => {
      if (state.currentStep > 1) {
        dispatch({ type: 'SET_STEP', payload: state.currentStep - 1 });
      }
    },
    updateOrderData: (data) => {
      dispatch({ type: 'UPDATE_ORDER_DATA', payload: data });
    },
    setStepValidation: (step, isValid) => {
      dispatch({ 
        type: 'UPDATE_VALIDATION', 
        payload: { step, isValid }
      });
    },
    canProceed: (step) => state.stepValidation[step],
    isLastStep: () => state.currentStep === state.totalSteps,
    showForm: () => dispatch({ type: 'SHOW_FORM' }),
    hideForm: () => dispatch({ type: 'HIDE_FORM' }),
    initOrder: (orderId, username, manufacturer) => 
      dispatch({ 
        type: 'INIT_ORDER', 
        payload: { orderId, username, manufacturer } 
      }),
  };

  return (
    <OrderStepContext.Provider value={value}>
      {children}
    </OrderStepContext.Provider>
  );
}

export function useOrderStep() {
  const context = useContext(OrderStepContext);
  if (!context) {
    throw new Error('useOrderStep must be used within an OrderStepProvider');
  }
  return context;
}
