import React from 'react';
import { useOrderStep } from '../../context/OrderStepContext';

export function StepBase({ children, stepNumber, title }) {
  const { state, previousStep, nextStep, canProceed, isLastStep } = useOrderStep();
  const { currentStep } = state;

  return (
    <div className="step-container">
      <div className="step-header">
        <h3>{title}</h3>
      </div>
      
      <div className="step-content">
        {children}
      </div>
      
      <div className="step-actions">
        {stepNumber > 1 && (
          <button
            type="button"
            className="button secondary-button"
            onClick={previousStep}
          >
            ← Нанзад
          </button>
        )}
        
        {!isLastStep() ? (
          <button
            type="button"
            className="button primary-button"
            onClick={nextStep}
            disabled={!canProceed(stepNumber)}
          >
            Понатаму →
          </button>
        ) : (
          <button
            type="submit"
            className="button primary-button"
            disabled={!canProceed(stepNumber)}
          >
            Внеси
          </button>
        )}
      </div>
    </div>
  );
}
