/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Modal from './invoiceEditingModal';
import '../css/Invoice.css';
import { FaCheck, FaEdit } from 'react-icons/fa';
import { db, ref, get, set, push } from "../firebase";
import { db2 } from "../firebase";
import ModalCompamny from './invoiceEditingModalCompany';

// eslint-disable-next-line react/prop-types
const Invoice = ({ documentType, clientName, order }) => {
  const [items, setItems] = useState([{ description: '', quantity: 1, price: 0.0, isEditing: true }]);
  const [invoiceDetails, setInvoiceDetails] = useState({
    dateOfIssue: order.OrderDate || new Date().toLocaleDateString("en-GB"),
    datePaymentDue: order.dueDate || new Date().toLocaleDateString("en-GB"),
    footer: "Ве молиме горниот износ да го платите во наведениот рок.\nВо спротивно пресметуваме затезна камата.\nВо случај на спор решава надлежниот Основен суд во Прилеп.",
    companyHeaderInfo: "Адреса: Републиканска 92\n7500 Прилеп, Македонија\n\nТелефон 076 283 396\nvesna.buzleska@somi-tek.com\n\nжиро сметка: 250-0290007699-84\nШпаркасе Банка\n\nБрој за ДДВ: MK 4021014523750",
    logoURL: "https://firebasestorage.googleapis.com/v0/b/crm-somitek.appspot.com/o/logo-somitek.png?alt=media&token=8cf270f3-76db-48d4-943d-c27ed256f24b",
    clientName: clientName || "Внеси име на клиент",
    clientAddress: "Внеси адреса\nна клиент\nво овај формат",
    poInformacii: "PO...",
    invoiceNumber: order.invoiceNumber ||"XXX/YY",
    documentType: documentType || "Фактура",
    clientDatabaseName: order.Buyer ||"No Client", 
    clientDBID: "",
  });

  const [companyInfo, setCompanyInfo] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentField, setCurrentField] = useState('');
  const [currentValue, setCurrentValue] = useState('');
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [templateName, setTemplateName] = useState('');
  const [editAdress, setEditAdress] = useState(false);

  const fetchTemplates = async () => {
    const templateRef = ref(db, 'InvoiceTemplates');
    const snapshot = await get(templateRef);
    const data = snapshot.val();
    if (data) {
      const fetchedTemplates = Object.entries(data).map(([key, value]) => ({ id: key, ...value }));
      setTemplates(fetchedTemplates);
    }
  };

  const handleAddressSave = async (name, adress) => {
    setCompanyInfo((prevDetails)=> ({
        ...prevDetails,
        clientName: name || "Внеси име на клиент",
        clientAddress: adress ||"Внеси адреса\nна клиент\nво овај формат",
    }))
    const companyRef = ref(db2, `companies/${companyInfo.id}`);
    set(companyRef, companyInfo)
      .then(() => {console.log('Company updated successfully')
        setInvoiceDetails((prevDetails) => ({
            ...prevDetails,
            clientName: name || "Внеси име на клиент",
            clientAddress: adress ||"Внеси адреса\nна клиент\nво овај формат",
          }));
          setEditAdress(false);
      })
      .catch(error => console.error('Error updating company:', error));
  };

  useEffect(() => {
    if (order && order.orderItems) {
      setItems(order.orderItems.map(item => ({
        description: item.description,
        quantity: parseInt(item.quantity, 10) || 1,
        price: parseFloat(item.price) || 0.0,
        isEditing: false
      })));
    }
  }, [order]);
  
  useEffect(() => {
    fetchTemplates();
  }, []);

  useEffect(() => {
    if (order) {
      const fetchCompany = async () => {
        try {
          const companiesRef = ref(db2, 'companies');
          const snapshot = await get(companiesRef);
          if (snapshot.exists()) {
            const companies = snapshot.val();
            // Find the company where ime matches order.Buyer
            const company = Object.values(companies).find(c => c.ime === order.Buyer);
            if (company) {
                setCompanyInfo(company);
              setInvoiceDetails((prevDetails) => ({
                ...prevDetails,
                clientName: company.clientName || "Внеси име на клиент",
                clientAddress: company.clientAddress ||"Внеси адреса\nна клиент\nво овај формат",
                clientDBID: company.id || "",
              }));
            } else {
              console.log('No company found with the name:', order.Buyer);
            }
          } else {
            console.log('No companies found in the database');
          }
        } catch (error) {
          console.error('Error fetching companies data:', error);
        }
      };

      fetchCompany();
    }
  }, [order]);


  const handleSaveTemplate = async () => {
    if (!templateName.trim()) {
      alert("Please provide a valid template name.");
      return;
    }
  
    const newTemplateRef = ref(db, 'InvoiceTemplates');
    const newTemplateKey = (await push(newTemplateRef)).key;
  
    const newTemplate = {
      ...invoiceDetails,
      templateName: templateName,
      id: newTemplateKey
    };
  
    try {
      await set(ref(db, `InvoiceTemplates/${newTemplateKey}`), newTemplate);
      alert('Template saved successfully!');
      fetchTemplates();
    } catch (error) {
      console.error("Error saving template:", error);
    }
  };

  const handleSelectTemplate = (e) => {
    const templateId = e.target.value;
    setSelectedTemplate(templateId);
  
    const selected = templates.find(template => template.id === templateId);
    if (selected) {
      setInvoiceDetails({
        ...selected,
        dateOfIssue: selected.dateOfIssue ? new Date(selected.dateOfIssue) : new Date(),
        datePaymentDue: selected.datePaymentDue ? new Date(selected.datePaymentDue) : new Date(),
      });
    }
  };

  const handleAddItem = () => {
    setItems([...items, { description: '', quantity: 1, price: 0.0, isEditing: true }]);
  };

  const handleRemoveItem = (index) => {
    const newItems = items.filter((_, i) => i !== index);
    setItems(newItems);
  };

  const handleChange = (index, field, value) => {
    const newItems = items.map((item, i) => {
      if (i === index) {
        return {
          ...item,
          [field]: field === 'price' ? parseFloat(value) || 0 : value
        };
      }
      return item;
    });
    setItems(newItems);
  };

  const handleSaveItem = (index) => {
    setItems((prevItems) => {
      const newItems = [...prevItems];
      newItems[index].isEditing = false;
      return newItems;
    });
  };

  const handleToggleEdit = (index) => {
    setItems((prevItems) => {
      const newItems = [...prevItems];
      newItems[index].isEditing = true;
      return newItems;
    });
  };

  const calculateTotal = () => {
    return items.reduce((total, item) => total + item.quantity * item.price, 0).toFixed(2);
  };

  const handlePrint = () => {
    window.print();
  };

  const openModal = (field) => {
    setCurrentField(field);
    setCurrentValue(invoiceDetails[field]);
    setIsModalOpen(true);
  };

  const handleSave = (field, value) => {
    setInvoiceDetails((prev) => ({ ...prev, [field]: value }));
    setIsModalOpen(false);
  };

  return (
    <div className='invoice-page-total'>
      <div className='button-container'>
        <button className="print-button" onClick={handlePrint}>Print to PDF</button>
        <button className="print-button" onClick={handleSaveTemplate}>Зачувај како Урнек</button>
      </div>

      <input 
        type="text" 
        placeholder="Enter template name" 
        value={templateName} 
        onChange={(e) => setTemplateName(e.target.value)} 
      />

      <select value={selectedTemplate} onChange={handleSelectTemplate}>
        <option value="">Изберете урнек</option>
        {templates.map(template => (
          <option key={template.id} value={template.id}>
            {template.templateName || template.invoiceNumber}
          </option>
        ))}
      </select>

      <div className="invoice" id="invoice">
        <header className="invoice-header">
          <table className="invoice-company">
            <thead>
              <tr>
                <th className='invoice-company-logo'>
                  <img src={invoiceDetails.logoURL} alt="Company Logo" style={{ maxWidth: '75mm', height: 'auto' }} />
                </th>
                <th className='invoice-company-details'>
                  <div className="box-for-company-details">
                    <p onClick={() => openModal('companyHeaderInfo')} dangerouslySetInnerHTML={{ __html: invoiceDetails.companyHeaderInfo.replace(/\n/g, '<br />') }} />
                  </div>
                </th>
              </tr>
            </thead>
          </table>
          <div className="invoice-client-information">
            <h3 onClick={() => setEditAdress(true)}>
              {invoiceDetails.clientName}
            </h3>
            <p onClick={() => setEditAdress(true)} dangerouslySetInnerHTML={{ __html: invoiceDetails.clientAddress.replace(/\n/g, '<br />') }} />
          </div>
          <table className="invoice-number">
            <thead>
              <tr>
                <th className='invoice-number-number'>
                  <h2>
                    <span onClick={() => openModal('documentType')}>
                      {invoiceDetails.documentType}
                    </span> бр.
                    <span onClick={() => openModal('invoiceNumber')}>
                      {invoiceDetails.invoiceNumber}
                    </span>
                  </h2>
                  <p onClick={() => openModal('poInformacii')}>
                    {invoiceDetails.poInformacii}
                  </p>
                </th>
                <th className='invoice-number-date'>
                  <p>Датум: {invoiceDetails.dateOfIssue}</p>
                  <p>Валута: {invoiceDetails.datePaymentDue}</p>
                </th>
              </tr>
            </thead>
          </table>
        </header>

        <main className="invoice-body">
          <table className="invoice-table">
            <thead>
              <tr>
                <th>Р. бр.</th>
                <th>Артикал/услуга</th>
                <th>Количина</th>
                <th>Единечна цена</th>
                <th>Вкупно</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    {item.isEditing ? (
                      <div>
                                            <input
                                            type="text"
                                            value={item.name}
                                            onChange={(e) => handleChange(index, 'name', e.target.value)}
                                            required
                                          />
                      <textarea
                        type="text"
                        value={item.description}
                        onChange={(e) => handleChange(index, 'description', e.target.value)}
                        placeholder="Опис/Коментар/потекло"
                        className="form-control"
                        rows="2"
                      />
                      </div>
                    ) : (
                      <div>
                      <h3 className='product-name-invoice'>{item.name}</h3>
                      {item.description}
                      </div>
                    )}
                  </td>
                  <td>
                    {item.isEditing ? (
                      <input
                        type="number"
                        value={item.quantity}
                        onChange={(e) => handleChange(index, 'quantity', e.target.value)}
                        min="1"
                      />
                    ) : (
                      item.quantity
                    )}
                  </td>
                  <td>
                    {item.isEditing ? (
                      <input
                        type="number"
                        value={item.price}
                        onChange={(e) => handleChange(index, 'price', e.target.value)}
                        min="0"
                        step="0.01"
                      />
                    ) : (
                      item.price.toFixed(2)
                    )}
                  </td>
                  <td>
                    {(item.price * item.quantity).toFixed(2)}
                  </td>
                  <div className="buttons-action">
                    {item.isEditing ? (
                      <button className="action-icon" onClick={() => handleSaveItem(index)}>
                        <FaCheck />
                      </button>
                    ) : (
                      <button className="action-icon" onClick={() => handleToggleEdit(index)}>
                        <FaEdit />
                      </button>
                    )}
                    <button className="delete-button" onClick={() => handleRemoveItem(index)}>X</button>
                  </div>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan="4"><strong>Вкупно: </strong></td>
                <td><strong>{calculateTotal()}</strong></td>
              </tr>
            </tfoot>
          </table>

          <div className="invoice-actions">
            <button onClick={handleAddItem}>+</button>
          </div>
        </main>

        <footer className="invoice-footer">
          <p 
            onClick={() => openModal('footer')} 
            dangerouslySetInnerHTML={{ 
              __html: invoiceDetails.footer.replace(/\n/g, '<br />') 
            }} 
          />
        </footer>
      </div>

      {isModalOpen && (
        <Modal
          title="Edit Field"
          value={currentValue}
          onSave={(value) => handleSave(currentField, value)}
          onClose={() => setIsModalOpen(false)}
        />
      )}
      {editAdress &&(
        <ModalCompamny 
        title = {(
            <>
              Внесeте правно име и адреса за <i>{invoiceDetails.clientDatabaseName}</i>
            </>
          )}
        
        value = {invoiceDetails.clientName}
        value2={invoiceDetails.clientAddress}
        onSave = {(value, value2) => handleAddressSave(value, value2)}
        onClose={()=> setEditAdress(false)}

        />
      )

      }
    </div>
  );
};

export default Invoice;
