import React, { useEffect, useState } from 'react';
import { StepBase } from './StepBase';
import { useOrderStep } from '../../context/OrderStepContext';

export function ItemsStep() {
  const { state, dispatch, setStepValidation, updateOrderData } = useOrderStep();
  const { orderData } = state;
  const [itemInputType, setItemInputType] = useState('file'); // 'list' or 'file'

  const handleItemInputTypeChange = (type) => {
    setItemInputType(type);
    if (type === 'file') {
      // Clear items if switching to file upload
      dispatch({
        type: 'CLEAR_ITEMS'
      });
    }
  };

  const addItem = () => {
    if (itemInputType === 'list') {
      dispatch({
        type: 'ADD_ITEM',
        payload: { description: '', quantity: 1, price: 0 }
      });
    }
  };

  const updateItem = (index, field, value) => {
    if (itemInputType === 'list') {
      const updatedItem = {
        ...orderData.orderItems[index],
        [field]: value
      };
      
      dispatch({
        type: 'UPDATE_ITEM',
        payload: { index, item: updatedItem }
      });
    }
  };

  const removeItem = (index) => {
    if (itemInputType === 'list') {
      dispatch({
        type: 'REMOVE_ITEM',
        payload: index
      });
    }
  };

  // Validate step data
  useEffect(() => {
    let isValid = false;
    
    if (itemInputType === 'list') {
      isValid = orderData.orderItems.length > 0 && 
        orderData.orderItems.every(item => 
          item.description && 
          item.quantity > 0 && 
          item.price >= 0
        );
    } else {
      // If file upload is selected, this step is always valid
      isValid = true;
    }

    // Update the order data with the selected input type
    updateOrderData({ itemInputType });
    setStepValidation(3, isValid);
  }, [orderData.orderItems, itemInputType, setStepValidation, updateOrderData]);

  return (
    <StepBase stepNumber={3} title="Понуда/документ">
      <div className="form-group">
        <label className="label">Прикачи постеочки документ или создадај понуда?</label>
        <div className="order-type-options">
          <label>
            <input
              type="radio"
              name="itemInputType"
              value="list"
              checked={itemInputType === 'list'}
              onChange={() => handleItemInputTypeChange('list')}
              disabled
            />
            <span className="pill">Нова Понуда (НАСКОРО)</span>
          </label>
          <label>
            <input
              type="radio"
              name="itemInputType"
              value="file"
              checked={itemInputType === 'file'}
              onChange={() => handleItemInputTypeChange('file')}
            />
            <span className="pill">Прикачи Документ</span>
          </label>
        </div>
      </div>

      {itemInputType === 'list' && (
        <div className="items-section">
          <table>
            <thead>
              <tr>
                <th>Назив</th>
                <th>Опис</th>
                <th>Количина</th>
                <th>Ед. Цена</th>
                <th>Вкупно</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {orderData.orderItems.map((item, index) => (
                <tr key={index}>
                   <td>
                    <input
                      type="text"
                      value={item.name}
                      onChange={(e) => updateItem(index, 'name', e.target.value)}
                      placeholder="Артикал/Усчлуга"
                      required
                    />
                  </td>
                  <td>
                    <textarea
                      type="text"
                      value={item.description}
                      onChange={(e) => updateItem(index, 'description', e.target.value)}
                      placeholder="Опис/Коментар/потекло"
                      className="form-control"
            rows="2"
            style={{ resize: 'vertical' }}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      value={item.quantity}
                      onChange={(e) => updateItem(index, 'quantity', parseInt(e.target.value, 10))}
                      min="1"
                      required
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      value={item.price}
                      onChange={(e) => updateItem(index, 'price', parseFloat(e.target.value))}
                      min="0"
                      step="0.01"
                      required
                    />
                  </td>
                  <td>
                    {(item.quantity * item.price).toFixed(2)}
                  </td>
                  <td>
                    <button
                      type="button"
                      className="button danger-button"
                      onClick={() => removeItem(index)}
                    >
                      Одстрани
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan="3" className="text-right">Total:</td>
                <td>
                  {orderData.orderItems
                    .reduce((sum, item) => sum + (item.quantity * item.price), 0)
                    .toFixed(2)
                  }
                </td>
                <td></td>
              </tr>
            </tfoot>
          </table>
          <div className="button-row">
            <button
              type="button"
              className="button primary-button"
              onClick={addItem}
            >
              Додади
            </button>
          </div>
        </div>
      )}

      {itemInputType === 'file' && (
        <div className="notice-message">
          Ќе прикачете документ во подледниот чекор.
        </div>
      )}
    </StepBase>
  );
}
