// src/pages/CompanyPage.js
import React, { useState, useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import CompanyList from '../elements/CompanyList';
import '../css/CompanyPage.css'; // Import CSS for styling

const CompanyPage = () => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [searchParams] = useSearchParams();
  const location = useLocation();

  useEffect(() => {
    // Check if openModal=true is in the URL
    const shouldOpenModal = searchParams.get('openModal') === 'true';
    if (shouldOpenModal) {
      setShowAddModal(true);
      // Remove the parameter from URL without refreshing the page
      const newUrl = location.pathname;
      window.history.replaceState({}, '', newUrl);
    }
  }, [location.pathname, searchParams]);

  const handleCloseModal = () => {
    setShowAddModal(false);
  };

  return (
    <div className="company-page">
      <CompanyList 
        showAddModal={showAddModal} 
        onCloseModal={handleCloseModal} 
      />
    </div>
  );
};

export default CompanyPage;
