/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import firebase from '../firebaseOLD';
import { ref, get } from 'firebase/database';
import { db2 } from '../firebase';
import '../css/editOrderForm.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const EditOrderForm = ({ order, onClose, userRole }) => {
  const [updatedOrder, setUpdatedOrder] = useState(order);
  const [uploading, setUploading] = useState({ invoice: false, other: false });
  const [selectedOrder, setSelectedOrder] = useState(order);
  const [relatedOrders, setRelatedOrders] = useState([]);
  const [originalVendor, setOriginalVendor] = useState(order.OrderVendor || '');
  const [buyerInput, setBuyerInput] = useState(order.Buyer || '');
  const [showDropdown, setShowDropdown] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [filteredCompanies, setFilteredCompanies] = useState([]);
  const [showCompanyModal, setShowCompanyModal] = useState(false);
  const [companySearch, setCompanySearch] = useState('');

  // Fetch companies from db2
  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const companiesRef = ref(db2, 'companies');
        const snapshot = await get(companiesRef);
        if (snapshot.exists()) {
          const companiesData = snapshot.val();
          const companiesArray = Object.entries(companiesData).map(([id, data]) => ({
            id,
            ...data
          }));
          setCompanies(companiesArray);
        }
      } catch (error) {
        console.error('Error fetching companies:', error);
      }
    };

    fetchCompanies();
  }, []);

  // Filter companies based on search
  useEffect(() => {
    if (companySearch.trim() === '') {
      setFilteredCompanies(companies.slice(0, 5)); // Show only top 5 when no search
    } else {
      const searchTerm = companySearch.toLowerCase();
      const filtered = companies
        .filter(company => 
          company.ime?.toLowerCase().includes(searchTerm) ||
          company.grad_ime?.toLowerCase().includes(searchTerm) ||
          company.drzava_ime?.toLowerCase().includes(searchTerm)
        )
        .slice(0, 5); // Limit filtered results to top 5
      setFilteredCompanies(filtered);
    }
  }, [companySearch, companies]);

  useEffect(() => {
    const fetchRelatedOrders = async () => {
      const dbRef = firebase.database().ref('orders');
      try {
        let allRelatedOrders = [];
        const currentOrderId = order.OrderID || order.id;
        
        // Check if this is a derivative order by looking for non-empty derivedFrom string
        if (order.derivedFrom && typeof order.derivedFrom === 'string' && order.derivedFrom.trim() !== '') {
          // This is a derivative order - fetch its parent first
          const baseSnapshot = await dbRef.child(order.derivedFrom).once('value');
          const baseOrder = baseSnapshot.val();
          
          if (baseOrder) {
            // Add the parent order
            allRelatedOrders.push({
              ...baseOrder,
              id: order.derivedFrom,
              OrderID: baseOrder.OrderID,
              derivativeOrders: baseOrder.derivativeOrders || []
            });

            // Fetch all derivatives of this parent
            const derivativesSnapshot = await dbRef
              .orderByChild('derivedFrom')
              .equalTo(order.derivedFrom)
              .once('value');

            derivativesSnapshot.forEach(childSnapshot => {
              allRelatedOrders.push({
                ...childSnapshot.val(),
                id: childSnapshot.key,
                OrderID: childSnapshot.val().OrderID
              });
            });
          }
        } else {
          // This is a parent order - add it first
          allRelatedOrders.push({
            ...order,
            id: order.id,
            OrderID: order.OrderID,
            derivativeOrders: order.derivativeOrders || []
          });

          // Fetch all derivatives
          const derivativesSnapshot = await dbRef
            .orderByChild('derivedFrom')
            .equalTo(order.id)  // Use the ID, not OrderID for the query
            .once('value');

          derivativesSnapshot.forEach(childSnapshot => {
            const derivativeOrder = childSnapshot.val();
            // Add all derivatives that reference this parent
            if (derivativeOrder.derivedFrom === order.id) {
              allRelatedOrders.push({
                ...derivativeOrder,
                id: childSnapshot.key,
                OrderID: derivativeOrder.OrderID
              });
            }
          });
        }

        // Sort orders so parent order is first, followed by derivatives
        allRelatedOrders.sort((a, b) => {
          if (a.derivativeOrders) return -1;
          if (b.derivativeOrders) return 1;
          return 0;
        });

        setRelatedOrders(allRelatedOrders);
      } catch (error) {
        console.error('Error fetching related orders:', error);
      }
    };

    fetchRelatedOrders();
  }, [order]);

  const handleOrderSelect = (orderId) => {
    const selectedOrder = relatedOrders.find(o => o.id === orderId);
    if (selectedOrder) {
      setSelectedOrder(selectedOrder);
      setUpdatedOrder(selectedOrder);
      setBuyerInput(selectedOrder.Buyer || '');
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdatedOrder((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    if (name === 'Advance') {
      const formattedValue = parseFloat(value.replace(/,/g, '')).toLocaleString(undefined, { minimumFractionDigits: 2 });
      setUpdatedOrder((prevState) => ({
        ...prevState,
        [name]: formattedValue,
      }));
    }
  };

  const handleBuyerChange = (e) => {
    setBuyerInput(e.target.value);
  };

  const handleBuyerSelect = (company) => {
    setBuyerInput(company.ime);
    setUpdatedOrder(prevState => ({
      ...prevState,
      Buyer: company.ime
    }));
    setShowDropdown(false);
  };

  const handleCompanySelect = (company) => {
    setUpdatedOrder(prevState => ({
      ...prevState,
      Buyer: company.ime
    }));
    setBuyerInput(company.ime);
    setShowCompanyModal(false);
  };

  const parseDateString = (dateStr) => {
    const [day, month, year] = dateStr.split('-').map(Number);
    return new Date(year, month - 1, day); // months are 0-based in JS
  };

  const formatDateToString = (date) => {
    if (!date) return '';
    
    if (typeof date === 'string') {
      const ddmmyyyyRegex = /^(\d{2})-(\d{2})-(\d{4})$/;
      if (ddmmyyyyRegex.test(date)) {
        return date;
      }
      const parsedDate = new Date(date);
      if (!isNaN(parsedDate.getTime())) {
        const day = parsedDate.getDate().toString().padStart(2, '0');
        const month = (parsedDate.getMonth() + 1).toString().padStart(2, '0');
        const year = parsedDate.getFullYear();
        return `${day}-${month}-${year}`;
      }
      return '';
    }
    
    if (date instanceof Date && !isNaN(date.getTime())) {
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    }
    
    return '';
  };

  const handleDateChange = (date, field) => {
    const formattedDate = date ? formatDateToString(date) : '';
    setUpdatedOrder(prevState => ({
      ...prevState,
      [field]: formattedDate
    }));
  };

  const handleDocumentUpload = (e, type) => {
    const file = e.target.files[0];
    if (!file) return;

    setUploading((prev) => ({ ...prev, [type]: true }));

    const storageRef = firebase.storage().ref();
    const docRef = storageRef.child(`documents/${updatedOrder.OrderID}/${file.name}`);

    docRef
      .put(file)
      .then((snapshot) => {
        snapshot.ref.getDownloadURL().then((url) => {
          setUpdatedOrder((prevState) => ({
            ...prevState,
            [`${type}Link`]: url,
          }));
          setUploading((prev) => ({ ...prev, [type]: false }));
        });
      })
      .catch(() => setUploading((prev) => ({ ...prev, [type]: false })));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (uploading.invoice || uploading.other) {
      alert('Please wait until all files are uploaded.');
      return;
    }

    if (!selectedOrder || !selectedOrder.id) {
      console.error('No selected order ID found');
      return;
    }

    const formattedOrder = {
      ...updatedOrder,
      OrderDate: formatDateToString(updatedOrder.OrderDate),
      DateOfDelivery: formatDateToString(updatedOrder.DateOfDelivery),
      DateOfPayment: formatDateToString(updatedOrder.DateOfPayment)
    };

    const dbRef = firebase.database().ref('orders').child(selectedOrder.id);

    dbRef
      .update(formattedOrder)
      .then(() => {
        console.log('Order updated successfully!');
        onClose();
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content edit-order-form">
        <button className="close-button" onClick={onClose}>
          ×
        </button>

        <div className="order-pills">
          {relatedOrders.map((relatedOrder, index) => {
            // For derivative orders, show their sequence number
            const derivativeNumber = relatedOrder.derivedFrom ? 
              relatedOrders.findIndex(o => o.id === relatedOrder.id) : null;
            
            return (
              <button
                key={relatedOrder.id}
                className={`order-pill ${selectedOrder?.id === relatedOrder.id ? 'active' : ''}`}
                onClick={() => handleOrderSelect(relatedOrder.id)}
                title={relatedOrder.OrderID}
              >
                {relatedOrder.derivedFrom ? (
                  <span className="derivative-order">
                    <span className="pill-order-id">
                      ↳ {derivativeNumber}
                    </span>
                    <span className="pill-company">{relatedOrder.Buyer}</span>
                  </span>
                ) : (
                  <span className="base-order">
                    <span className="pill-order-id">{relatedOrder.OrderID}</span>
                    <span className="pill-company">{relatedOrder.Buyer}</span>
                  </span>
                )}
              </button>
            );
          })}
        </div>

        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label className="label">Број на нарачка:</label>
            <input
              type="text"
              name="OrderID"
              value={updatedOrder?.OrderID}
              disabled
              className="input"
            />
          </div>
          <div className="form-group">
            <label className="label">Датум на нарачка:</label>
            <input
              type="text"
              name="OrderDate"
              value={updatedOrder?.OrderDate}
              disabled
              className="input"
            />
          </div>
          <div className="form-group">
            <label className="label">Производител:</label>
            <input
              type="text"
              name="OrderVendor"
              value={updatedOrder?.OrderVendor}
              onChange={handleChange}
              disabled={!updatedOrder?.OrderID.includes('S')}
              className="input"
            />
          </div>
          <div className="form-group">
            <label className="label">Купец:</label>
            <div className="buyer-display">
              <div className="buyer-text">
                {updatedOrder?.Buyer || 'Изберете купец'}
              </div>
              <button 
                type="button"
                className="edit-button"
                onClick={() => setShowCompanyModal(true)}
              >
                ✏️
              </button>
            </div>
          </div>

          {showCompanyModal && (
            <>
              <div className="company-modal-overlay" onClick={() => setShowCompanyModal(false)} />
              <div className="company-modal">
                <div className="modal-title">Изберете купец</div>
                <div className="company-search">
                  <input
                    type="text"
                    placeholder="Пребарувај купци..."
                    value={companySearch}
                    onChange={(e) => setCompanySearch(e.target.value)}
                    autoFocus
                  />
                </div>
                <div className="company-list">
                  {filteredCompanies.map((company) => (
                    <div
                      key={company.id}
                      className="company-item"
                      onClick={() => handleCompanySelect(company)}
                    >
                      <div className="company-name">{company.ime}</div>
                      <div className="company-country">
                        {company.grad_ime && company.drzava_ime 
                          ? `${company.grad_ime}, ${company.drzava_ime}`
                          : company.drzava_ime || company.grad_ime || 'Location not specified'}
                      </div>
                    </div>
                  ))}
                  {filteredCompanies.length === 0 && (
                    <div style={{ padding: '20px', textAlign: 'center', color: '#666' }}>
                      Нема пронајдени компании
                    </div>
                  )}
                </div>
                <div className="company-modal-footer">
                  <button
                    type="button"
                    className="add-company-button"
                    onClick={() => window.open('/companies?openModal=true', '_blank')}
                  >
                    + Додај нова фирма
                  </button>
                </div>
              </div>
            </>
          )}
          <div className="form-group">
            <label className="label">ОА број:</label>
            <input
              type="text"
              name="OriginationNumber"
              value={updatedOrder?.OriginationNumber}
              onChange={handleChange}
              className="input"
            />
          </div>
          {userRole === "admin" &&(
            <div>
            <div className="form-group">
            <label className="label">Датум на достава:</label>
            <DatePicker
              selected={updatedOrder?.DateOfDelivery ? parseDateString(updatedOrder.DateOfDelivery) : null}
              onChange={(date) => handleDateChange(date, 'DateOfDelivery')}
              dateFormat="dd-MM-yyyy"
              className="input"
              placeholderText="Select delivery date"
            />
          </div>
          <div className="form-group">
            <label className="label">Датум на уплата:</label>
            <DatePicker
              selected={updatedOrder?.DateOfPayment ? parseDateString(updatedOrder.DateOfPayment) : null}
              onChange={(date) => handleDateChange(date, 'DateOfPayment')}
              dateFormat="dd-MM-yyyy"
              className="input"
              placeholderText="Select payment date"
            />
          </div>
          </div>
          )}
          
          <div className="form-group">
            <label className="label">Коментар:</label>
            <textarea
              name="Comment"
              value={updatedOrder?.Comment}
              onChange={handleChange}
              className="input"
            />
          </div>
          <div className="form-group">
            <label className="label">Одговорен:</label>
            <input
              name="Username"
              value={updatedOrder?.Username}
              disabled
              className="input"
            />
          </div>
          <div className="form-group">
            <label className="label">Авансна Уплата (во Валута):</label>
            <input
              name="Advance"
              onBlur={handleBlur}
              value={updatedOrder?.Advance}
              onChange={handleChange}
              className="input"
            />
          </div>
          {userRole === "admin" &&(
          <div className="form-group">
            <label className="label">Статус:</label>
            <select
              name="Status"
              value={updatedOrder?.Status}
              onChange={handleChange}
              className="input"
            >
              <option value="Processing">Обработка</option>
              <option value="Invoiced">Фактурирано</option>
              <option value="Archived">Архивирај</option>
            </select>
          </div>
          )}
          <div className="form-group">
            <label className="label">Валута:</label>
            <select
              type="text"
              name="currency"
              value={updatedOrder?.currency}
              onChange={handleChange}
              className="input"
            >
              <option value="MK-MKD">Фирма:MK, Валута:MKD</option>
              <option value="MK-EUR">Фирма:MK, Валута:€</option>
              <option value="EU-EUR">Фирма:ЕУ, Валута:€</option>
              </select>
          </div>
          {userRole === "admin" &&(
<div>
          <div className="form-group">
            <label className="label">Сума:</label>
            <input
              type="text"
              name="totalSum"
              value={updatedOrder?.totalSum}
              onChange={handleChange}
              className="input"
            />
          </div>
          <div className="form-group">
            <label className="label">Датум за наплата (DD-MM-YYYY):</label>
            <input
              type="text"
              name="dueDate"
              value={updatedOrder?.dueDate}
              onChange={handleChange}
              placeholder="DD-MM-YYYY"
              className="input"
            />
          </div>
          </div>
          )}
          <div className="form-group">
            <label className="label">Док. за нарачка:</label>
            {updatedOrder?.OtherLink ? (
              <>
                <a href={updatedOrder.OtherLink} target="_blank" rel="noopener noreferrer">
                  Прегледај документ
                </a>
                <input type="file" onChange={(e) => handleDocumentUpload(e, 'Other')} />
              </>
            ) : (
              <input type="file" onChange={(e) => handleDocumentUpload(e, 'Other')} />
            )}
          </div>
          {userRole === "admin" &&(
          <div className="form-group">
            <label className="label">фактура:</label>
            {updatedOrder?.InvoiceLink ? (
              <>
                <a href={updatedOrder.InvoiceLink} target="_blank" rel="noopener noreferrer">
                  Прегледај документ
                </a>
                <input type="file" onChange={(e) => handleDocumentUpload(e, 'Invoice')} />
              </>
            ) : (
              <input type="file" onChange={(e) => handleDocumentUpload(e, 'Invoice')} />
            )}
          </div>
          )}
          <div className="form-actions">
            <button type="submit" className="btn-save">
              Зачувај промени
            </button>
            <button type="button" onClick={onClose} className="btn-discard">
              Одфрли промени
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditOrderForm;
