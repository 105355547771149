import React, { useEffect, useState } from 'react';
import { StepBase } from './StepBase';
import { useOrderStep } from '../../context/OrderStepContext';
import { v4 as uuidv4 } from 'uuid';
import firebase from '../../firebaseOLD';

// Helper function to parse DD-MM-YYYY to Date object
const parseDate = (dateStr) => {
  if (!dateStr) return null;
  const [day, month, year] = dateStr.split('-').map(Number);
  const date = new Date(year, month - 1, day);
  return isNaN(date.getTime()) ? null : date;
};

// Helper function to format date to DD-MM-YYYY string
const formatDate = (date) => {
  if (!date) return null;
  if (date instanceof Date) {
    return date.toLocaleDateString('en-GB');
  }
  if (typeof date === 'string') {
    // Check if date is already in DD-MM-YYYY format
    const dateRegex = /^(\d{2})-(\d{2})-(\d{4})$/;
    if (dateRegex.test(date)) {
      return date;
    }
    // Try to parse the date string
    const parsedDate = new Date(date);
    if (!isNaN(parsedDate.getTime())) {
      return parsedDate.toLocaleDateString('en-GB');
    }
  }
  return null;
};

// Helper function to get vendor letter
const getVendorLetter = (vendor) => {
  switch (vendor) {
    case 'Donaldson':
      return 'D';
    case 'Euroventilatori':
      return 'E';
    default:
      return 'S';
  }
};

// Helper function to get current year's last two digits
const getCurrentYearSuffix = () => {
  return new Date().getFullYear().toString().slice(-2);
};

export function BasicInfoStep({ activeOrders }) {
  const { state, updateOrderData, setStepValidation } = useOrderStep();
  const { orderData } = state;
  const [orderType, setOrderType] = useState('new');
  const [selectedOrder, setSelectedOrder] = useState(null);

  // Get the current manufacturer's active orders
  const currentManufacturerOrders = activeOrders[orderData.OrderVendor] || [];

  // Validate step data
  useEffect(() => {
    const isValid = orderData.Jurisdiction && 
      (orderType === 'new' || (orderType === 'derivative' && selectedOrder));
    setStepValidation(1, isValid);
  }, [orderData.Jurisdiction, orderType, selectedOrder]);

  const handleJurisdictionChange = async (e) => {
    const jurisdiction = e.target.value;
    const currentOrderData = { Jurisdiction: jurisdiction };

    // Only generate order number for new orders
    if (orderType === 'new' && orderData.OrderVendor) {
      try {
        const db = firebase.database();
        const invoicesRef = db.ref('invoices');
        
        // Get current counter values for the specific vendor
        const snapshot = await invoicesRef.child(orderData.OrderVendor).once('value');
        const vendorData = snapshot.val();
        
        if (!vendorData) {
          console.error('No vendor data found');
          return;
        }

        const isEU = jurisdiction === 'EU';
        const currentCounter = isEU 
          ? parseInt(vendorData.CurrentInvoiceEU)
          : parseInt(vendorData.CurrentInvoiceMK);
        
        // Generate order number using current counter
        const vendorLetter = getVendorLetter(orderData.OrderVendor);
        const yearSuffix = getCurrentYearSuffix();
        const orderNumber = isEU 
          ? `${currentCounter}${vendorLetter}${yearSuffix}B`
          : `${currentCounter}${vendorLetter}${yearSuffix}`;
        
        // Update order data with new order number and save counter info for later
        currentOrderData.OrderID = orderNumber;
        currentOrderData._counterInfo = {
          isEU,
          currentCounter,
          vendor: orderData.OrderVendor
        };
      } catch (error) {
        console.error('Error generating order number:', error);
      }
    }
    
    updateOrderData(currentOrderData);
  };

  const handleOrderTypeChange = (e) => {
    const newOrderType = e.target.value;
    setOrderType(newOrderType);
    
    if (newOrderType === 'new') {
      // Reset order data for new order
      updateOrderData({ 
        derivedFrom: null,
        OrderID: '',
        orderItems: [],
        documents: { invoice: null, other: null },
        Status: 'Processing',
        DateOfDelivery: null,
        DateOfPayment: null
      });
      setSelectedOrder(null);
    }
  };

  const handleBaseOrderSelect = async (e) => {
    const selectedOrderId = e.target.value;
    const selectedOrder = currentManufacturerOrders.find(order => order.OrderID === selectedOrderId);
    setSelectedOrder(selectedOrder);

    if (selectedOrder) {
      // Create a new order data object based on the selected order
      const orderDataToUpdate = {
        ...selectedOrder,                    // Copy all fields from the base order
        id: uuidv4(),                       // New unique ID for database
        OrderID: selectedOrder.OrderID,      // Keep the same order number visible to user
        derivedFrom: selectedOrder.id,       // Store reference to base order's ID
        orderItems: [],                      // Empty order items
        documents: { invoice: null, other: null },
        Status: 'Processing'
      };

      // Remove fields that shouldn't be copied
      delete orderDataToUpdate.derivativeOrders;
      delete orderDataToUpdate._counterInfo;

      // Keep the original dates
      orderDataToUpdate.DateOfDelivery = selectedOrder.DateOfDelivery;
      orderDataToUpdate.DateOfPayment = selectedOrder.DateOfPayment;

      updateOrderData(orderDataToUpdate);
    }
  };

  // Set vendor
  const handleVendorChange = (e) => {
    const vendor = e.target.value;
    updateOrderData({ OrderVendor: vendor });
  };

  const handleSubmit = async () => {
    if (orderData._counterInfo) {
      try {
        const db = firebase.database();
        const updates = {
          [`invoices/${orderData._counterInfo.vendor}/${orderData._counterInfo.isEU ? 'CurrentInvoiceEU' : 'CurrentInvoiceMK'}`]: (orderData._counterInfo.currentCounter + 1).toString()
        };
        
        // Update counter in database
        await db.ref().update(updates);
      } catch (error) {
        console.error('Error updating counter:', error);
      }
    }
  };

  return (
    <StepBase stepNumber={1} title="Започни нарачка" onSubmit={handleSubmit}>
      <div className='step-sub-content'>
      <div className="form-row">
        <div className="form-group">
          <label className="label">Order Type:</label>
          <div className="order-type-options">
            <label>
              <input
                type="radio"
                name="orderType"
                value="new"
                checked={orderType === 'new'}
                onChange={handleOrderTypeChange}
              />
              <span className="pill">Нова нарачка</span>
            </label>
            <label>
              <input
                type="radio"
                name="orderType"
                value="derivative"
                checked={orderType === 'derivative'}
                onChange={handleOrderTypeChange}
              />
              <span className="pill">Дериваивна/додатна</span>
            </label>
          </div>
        </div>

        {orderType === 'derivative' && currentManufacturerOrders.length > 0 && (
          <div className="form-group">
            <label className="label">Основна нарачка:</label>
            <select
              className="select"
              value={selectedOrder?.OrderID || ''}
              onChange={handleBaseOrderSelect}
            >
              <option value="">Одбери основна нарачка</option>
              {currentManufacturerOrders.map(order => (
                <option key={order.OrderID} value={order.OrderID}>
                  {order.OrderID} - {order.Buyer}
                </option>
              ))}
            </select>
          </div>
        )}

        {orderType === 'derivative' && selectedOrder && !(orderData.OrderVendor === 'Euroventilatori' || orderData.OrderVendor === 'Donaldson') && (
          <div className="form-group">
            <label className="label">Избрана нарачка:</label>
            <input
              type="text"
              name="OrderID"
              value={orderData.OrderID + ' (' + selectedOrder.OrderVendor + ')'}
              disabled
              className="input"
            />
          </div>
        )}

        {orderType === 'derivative' && currentManufacturerOrders.length === 0 && (orderData.OrderVendor === 'Euroventilatori' || orderData.OrderVendor === 'Donaldson') && (
          <div className="form-group">
            <p className="notice">Проблем со избраната нарачка. Провери дали се сите задолжителни полиња пополнети и обиди се повторно {orderData.OrderVendor}</p>
          </div>
        )}

        <div className="form-group">
          <label className="label">Фирма:</label>
          <div className="jurisdiction-options">
            <label>
              <input
                type="radio"
                name="Jurisdiction"
                value="MK"
                checked={orderData.Jurisdiction === 'MK'}
                onChange={handleJurisdictionChange}
              />
              <span className="pill">MK</span>
            </label>
            <label>
              <input
                type="radio"
                name="Jurisdiction"
                value="EU"
                checked={orderData.Jurisdiction === 'EU'}
                onChange={handleJurisdictionChange}
              />
              <span className="pill">EU</span>
            </label>
          </div>
        </div>
        
        <div className="form-group">
          <label className="label">Бр. Нарачка:</label>
          <input
            type="text"
            name="OrderID"
            value={orderData.OrderID}
            disabled
            className="input"
          />
        </div>

        <div className="form-group">
          <label className="label">Датум на нарачка:</label>
          <input
            type="text"
            name="OrderDate"
            value={formatDate(orderData.OrderDate)}
            disabled
            className="input"
          />
        </div>

        <div className="form-group">
          <label className="label">Производител:</label>
          <input
            type="text"
            name="OrderVendor"
            value={orderData.OrderVendor || ''}
            onChange={handleVendorChange}
            disabled={!orderData.OrderID || !orderData.OrderID.includes('S')}
            className="input manufacturer-input"
          />
        </div>
      </div>
      </div>
    </StepBase>
  );
}
