/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import '../css/ordersTable.css';
import InvoiceIcon from '../Icons/InvoiceIcon.js';
import EditOrderForm from './EditOrderForm.js';
import GenerateInvoiceModal from './GenerateInvoiceModal.js';
import CompanyInfoModal from './CompanyInfoModal';
import { ref, update, get } from 'firebase/database';
import { db2, db } from '../firebase.js';
import InvoiceMultipleModal from './GenerateInvoiceOAMultipleModal.js';
import EditOrderFormU from './EditOrderFormU.js';

const OrdersTableOthers = ({ user, userRole, orders, vendor }) => {
  const [previewUrl, setPreviewUrl] = useState('');
  const [editingOrder, setEditingOrder] = useState(null);
  const [editingOrderU, setEditingOrderU] = useState(null);
  const [showGenerateInvoiceModal, setShowGenerateInvoiceModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [companyInfo, setCompanyInfo] = useState(null);
  const [showCompanyInfoModal, setShowCompanyInfoModal] = useState(false);
  const [showMultipleInvoiceModal, setShowMultipleInvoiceModal] = useState(false);

  const handleEditOrder = (order) => {
    setEditingOrder(order);
  };

  const handleCloseModal = () => {
    setEditingOrder(null);
  };

  const handleEditOrderU = (order) => {
    setEditingOrderU(order);
  };

  const handleCloseModalU = () => {
    setEditingOrderU(null);
  };

  const handleGenerateInvoice = (order) => {
    setSelectedOrder(order);
    setShowGenerateInvoiceModal(true);
  };

  const handleMultipleInvoiceModal = (order) => {
    setSelectedOrder(order);
    setShowMultipleInvoiceModal(true);
  };

  const handleCloseMultipleInvoiceModal = () =>{
    setSelectedOrder(null);
    setShowMultipleInvoiceModal(false);
  }
  const handleCloseGenerateInvoiceModal = () => {
    setShowGenerateInvoiceModal(false);
    setSelectedOrder(null);
  };

  const handlePreviewInvoice = (invoiceLink) => {
    setPreviewUrl(invoiceLink);
  };

  const handleClosePreview = () => {
    setPreviewUrl('');
  };

  const handleOpenCompanyInfo = async (buyerName) => {
    try {
      const companiesRef = ref(db2, 'companies');
      const snapshot = await get(companiesRef);
      if (snapshot.exists()) {
        const companies = snapshot.val();
        const companyList = Object.values(companies);
        const foundCompany = companyList.find(company => company.ime === buyerName);
        if (foundCompany) {
          setCompanyInfo(foundCompany);
          setShowCompanyInfoModal(true);
        } else {
          console.log('Фирма не е пронајдена');
        }
      } else {
        console.log('Нема податоци за фирмите');
      }
    } catch (error) {
      console.error('Проблем при вчитување на фирмите:', error);
    }
  };

  const handleSendToDebtors = async (order) => {
    if (!order.validatedInvoice) {
      alert("Нарачката ги нема сите инфромации за фактура. ве молам потврдете дека се е во ред");
      return;
    }

    if (order.derivativeOrders) {
      for (const derivativeOrder of order.derivativeOrders) {
        if (!derivativeOrder.validatedInvoice) {
          alert("Ве молам потврдете дека сите под-нарачки имаат фактури и информациите за нив се точно пополнети");
          return;
        }
      }

      // Update all derivative orders
      for (const derivativeOrder of order.derivativeOrders) {
        const orderRef = ref(db, `orders/${derivativeOrder.id}`);
        await update(orderRef, {
          Status: "Invoiced",
        });
      }
    }

    // Update main order
    const orderRef = ref(db, `orders/${order.id}`);
    await update(orderRef, {
      Status: "Invoiced",
    });

    alert("Успешно ажурирани нарачки");
  };

  return (
    <div className="orders-table-container">
      <h2>{vendor}</h2>
      {previewUrl && (
        <div className="invoice-preview">
          <div className="preview-content">
            <button className="close-button" onClick={handleClosePreview}>&times;</button>
            <iframe src={previewUrl} title="Invoice Preview" className="preview-frame"></iframe>
          </div>
        </div>
      )}
      <table className="orders-table orders-table-2">
        <thead>
          <tr>
            <th>Број на нарачка</th>
            <th>Датум - нарачка</th>
            <th>ОА Број</th>
            <th>д. Испорака</th>
            <th>д. Уплата</th>
            <th>Купец</th>
            <th>Производител</th>
            <th>Коментари</th>
            <th>Док.</th>
            <th>Фирма</th>
            <th>Одговорно Лице</th>
            <th>Аванс Сума</th>
            {userRole === "admin" && <th>Подеси</th>}
          </tr>
        </thead>
        {orders.length > 0 ? (
          <tbody>
            {Object.keys(orders).map((id) => {
              const order = orders[id];
              return (
                <>
                  <tr key={id}>
                    {userRole === "admin" ? (
                      <td>
                        <a className='order-link' href="#" onClick={() => handleEditOrder(order)}>
                          {order.OrderID}
                        </a>
                      </td>
                    ) : (
                      <td>
                        <a className='order-link' href="#" onClick={() => handleEditOrderU(order)}>
                          {order.OrderID}
                        </a>
                      </td>
                    )}
                    <td>{order.OrderDate}</td>
                    {userRole === "admin" ? (
                      <td>
                        <a className='order-link' href="#" onClick={() => handleMultipleInvoiceModal(order)}>
                          {order.OriginationNumber}
                        </a>
                      </td>
                    ) : (
                      <td>{order.OriginationNumber}</td>
                    )}
                    <td>{order.DateOfDelivery}</td>
                    <td>{order.DateOfPayment}</td>
                    <td>
                      <a className='order-link' href="#" onClick={() => handleOpenCompanyInfo(order.Buyer)}>
                        {order.Buyer}
                      </a>
                    </td>
                    <td>{order.OrderVendor}</td>
                    <td>{order.Comment}</td>
                    <td>
                      {order.OtherLink !== '' && (
                        <button onClick={() => handlePreviewInvoice(order.OtherLink)}>
                          <InvoiceIcon />
                        </button>
                      )}
                    </td>
                    <td>{order.Jurisdiction}</td>
                    <td>{order.Username}</td>
                    <td>{order.Advance}</td>
                    {userRole === "admin" && !order.validatedInvoice && (
                      <td>
                        <button onClick={() => handleGenerateInvoice(order)}>+</button>
                      </td>
                    )}
                    {userRole === "admin" && order.validatedInvoice && (
                      <td>
                        <button onClick={() => handleSendToDebtors(order)}>→</button>
                      </td>
                    )}
                  </tr>
                  {order.derivativeOrders && order.derivativeOrders.map((derivativeOrder, index) => (
                    <tr key={`${id}-derivative-${index}`} className="derivative-order">
                      <td>
                        <span className="derivative-indicator">↳</span>
                        {derivativeOrder.OrderID}
                      </td>
                      <td>{derivativeOrder.OrderDate}</td>
                      <td>{derivativeOrder.OriginationNumber}</td>
                      <td>{derivativeOrder.DateOfDelivery}</td>
                      <td>{derivativeOrder.DateOfPayment}</td>
                      <td>
                      <a className='order-link' href="#" onClick={() => handleOpenCompanyInfo(order.Buyer)}>
                        {order.Buyer}
                      </a>
                      </td>
                      <td>{derivativeOrder.OrderVendor}</td>
                      <td>{derivativeOrder.Comment}</td>
                      <td>
                        {derivativeOrder.OtherLink !== '' && (
                          <button onClick={() => handlePreviewInvoice(derivativeOrder.OtherLink)}>
                            <InvoiceIcon />
                          </button>
                        )}
                      </td>
                      <td>{derivativeOrder.Jurisdiction}</td>
                      <td>{derivativeOrder.Username}</td>
                      <td>{derivativeOrder.Advance}</td>
                      {userRole === "admin" && !derivativeOrder.validatedInvoice && (
                        <td>
                          <button onClick={() => handleGenerateInvoice(derivativeOrder)}>+</button>
                        </td>
                      )}
                      {userRole === "admin" && derivativeOrder.validatedInvoice && (
                        <td></td>
                      )}
                    </tr>
                  ))}
                </>
              );
            })}
          </tbody>
        ) : (
          <p>Нема Активни</p>
        )}
      </table>
      {editingOrder && (
        <EditOrderForm
          order={editingOrder}
          user={user}
          onClose={handleCloseModal}
        />
      )}
        {editingOrderU && (
        <EditOrderFormU
          order={editingOrderU}
          user={user}
          onClose={handleCloseModalU}
        />
      )}
      {showGenerateInvoiceModal && (
        <GenerateInvoiceModal
          currentOrderID={selectedOrder.id}
          order={selectedOrder}
          onClose={handleCloseGenerateInvoiceModal}
        />
      )}
            {showMultipleInvoiceModal && (
        <InvoiceMultipleModal
        ordersOANum={selectedOrder.OriginationNumber}
          onClose={handleCloseMultipleInvoiceModal}
        />
      )}
      {showCompanyInfoModal && companyInfo && (
        <CompanyInfoModal
          company={companyInfo}
          onClose={() => setShowCompanyInfoModal(false)}
        />
      )}
    </div>
  );
};

export default OrdersTableOthers;
