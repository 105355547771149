/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { OrderStepProvider, useOrderStep } from '../context/OrderStepContext';
import AddOrderForm from '../pages/addOrderForm';
import AddOrderFormOthers from '../pages/addOrderFormOthers';
import OrdersTable from '../elements/ordersTable';
import OrdersTableOthers from '../elements/ordersTableOthers';
import firebase from '../firebaseOLD';
import { ref, get } from 'firebase/database';
import { db2 } from '../firebase';
import '../css/tableHome.css';

function TableContent({ user, userRole, userName }) {
  const { initOrder, state } = useOrderStep();
  const [donaldsonData, setDonaldsonData] = useState([]);
  const [euroventilatoriData, setEuroventilatoriData] = useState([]);
  const [otherData, setOtherData] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [currentInvoices, setCurrentInvoices] = useState({
    Donaldson: { CurrentInvoiceEU: "0", CurrentInvoiceMK: "0" },
    Euroventilatori: { CurrentInvoiceEU: "0", CurrentInvoiceMK: "0" },
    Other: { CurrentInvoiceEU: "0", CurrentInvoiceMK: "0" }
  });

  const handleAddOrder = (manufacturer) => {
    const newOrderId = generateOrderId(manufacturer);
    initOrder(newOrderId, userName, manufacturer);
  };

  const generateOrderId = (manufacturer) => {
    const date = new Date();
    const year = date.getFullYear().toString().slice(-2);
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const prefix = manufacturer === 'Donaldson' ? 'D' : 'E';
    
    const manufacturerOrders = manufacturer === 'Donaldson' ? donaldsonData : euroventilatoriData;
    const lastOrder = manufacturerOrders[manufacturerOrders.length - 1];
    const lastNumber = lastOrder ? parseInt(lastOrder.OrderID.slice(-3)) : 0;
    const newNumber = (lastNumber + 1).toString().padStart(3, '0');
    
    return `${prefix}${year}${month}${newNumber}`;
  };

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const companiesRef = ref(db2, 'companies');
        const snapshot = await get(companiesRef);
        if (snapshot.exists()) {
          const companyList = Object.values(snapshot.val());
          setCompanies(companyList);
        }
      } catch (error) {
        console.error('Error fetching companies:', error);
      }
    };

    fetchCompanies();
  }, []);

  useEffect(() => {
    const dbRef = firebase.database().ref('/orders');
    const dbInvoice = firebase.database().ref('/invoices');

    const fetchAndUpdateInvoices = () => {
      dbInvoice.on('value', snapshot => {
        const invoicesData = snapshot.val();
        if (invoicesData) {
          const updatedInvoices = {};
          Object.keys(invoicesData).forEach(key => {
            const invoice = invoicesData[key];
            updatedInvoices[key] = {
              CurrentInvoiceEU: invoice.CurrentInvoiceEU || "0",
              CurrentInvoiceMK: invoice.CurrentInvoiceMK || "0",
            };
          });
          setCurrentInvoices(prevState => ({
            ...prevState,
            ...updatedInvoices
          }));
        }
      });
    };

    const processOrders = (orders) => {
      if (!orders) return [];
      
      // First, get all active orders
      const activeOrders = Object.keys(orders)
        .filter(key => orders[key].Status === 'Processing')
        .map(key => ({
          id: key,
          ...orders[key]
        }));

      // Separate base orders and derivative orders
      const baseOrders = activeOrders.filter(order => !order.derivedFrom);
      const derivativeOrders = activeOrders.filter(order => order.derivedFrom);

      // Create a map of derivative orders by their base order ID
      const derivativeOrdersMap = derivativeOrders.reduce((acc, order) => {
        if (!acc[order.derivedFrom]) {
          acc[order.derivedFrom] = [];
        }
        acc[order.derivedFrom].push(order);
        return acc;
      }, {});

      // Create the final hierarchical structure
      const hierarchicalOrders = baseOrders.map(baseOrder => {
        const derivatives = derivativeOrdersMap[baseOrder.id] || [];
        return {
          ...baseOrder,
          derivativeOrders: derivatives.sort((a, b) => 
            new Date(a.CreatedAt) - new Date(b.CreatedAt)
          )
        };
      });

      // Sort base orders by creation date
      return hierarchicalOrders.sort((a, b) => 
        new Date(b.CreatedAt) - new Date(a.CreatedAt)
      );
    };

    const fetchAllOrdersAndListen = () => {
      dbRef.orderByChild('Status').equalTo('Processing').on('value', snapshot => {
        const orders = snapshot.val();
        if (orders) {
          const allOrders = Object.entries(orders).map(([id, order]) => ({
            id,
            ...order
          }));
          // Group orders by vendor
          const donaldsonOrders = allOrders.filter(order => order.OrderVendor === 'Donaldson');
          const euroventilatoriOrders = allOrders.filter(order => order.OrderVendor === 'Euroventilatori');
          const otherOrders = allOrders.filter(order => 
            order.OrderVendor !== 'Donaldson' && order.OrderVendor !== 'Euroventilatori'
          );

          // Convert each group to hierarchical structure with derivatives
          const processedDonaldsonOrders = processOrders(
            donaldsonOrders.reduce((acc, order) => ({ ...acc, [order.id]: order }), {})
          );
          const processedEuroventilatoriOrders = processOrders(
            euroventilatoriOrders.reduce((acc, order) => ({ ...acc, [order.id]: order }), {})
          );
          const processedOtherOrders = processOrders(
            otherOrders.reduce((acc, order) => ({ ...acc, [order.id]: order }), {})
          );

          // Update state for each table
          setDonaldsonData(processedDonaldsonOrders);
          setEuroventilatoriData(processedEuroventilatoriOrders);
          setOtherData(processedOtherOrders);
        } else {
          setDonaldsonData([]);
          setEuroventilatoriData([]);
          setOtherData([]);
        }
      });
    };

    fetchAllOrdersAndListen();
    fetchAndUpdateInvoices();

    return () => {
      dbRef.off();
      dbInvoice.off();
    };
  }, []);

  if (userRole !== 'admin' && userRole !== 'user') {
    return <h1 style={{ color: "red" }}>Неавторизиран пристап</h1>;
  }

  return (
    <div style={{ padding: '40px' }}>
      <h1>Тековни Нарачки</h1>
      <div>
        <OrdersTable user={user} userRole={userRole} vendor="Donaldson" orders={donaldsonData} />
        <div className="table-actions">
          <button
            className="add-order-button"
            onClick={() => handleAddOrder('Donaldson')}
          >
            + Додај Donaldson Нарачка
          </button>
        </div>
      </div>

      <div>
        <OrdersTable user={user} userRole={userRole} vendor="Euroventilatori" orders={euroventilatoriData} />
        <div className="table-actions">
          <button
            className="add-order-button"
            onClick={() => handleAddOrder('Euroventilatori')}
          >
            + Додај Euroventilatori Нарачка
          </button>
        </div>
      </div>

      <div>
        <OrdersTableOthers user={user} userRole={userRole} vendor="Други" orders={otherData} />
        <div className="table-actions">
          <button
            className="add-order-button"
            onClick={() => handleAddOrder('Other')}
          >
            + Додај Нарачка
          </button>
        </div>
      </div>

      {state.isVisible && (
        <AddOrderForm
          userRole={userRole}
          user={user}
          userName={userName}
          companies={companies}
          activeOrders={{
            Donaldson: donaldsonData,
            Euroventilatori: euroventilatoriData,
            Other: otherData
          }}
        />
      )}
    </div>
  );
}

function TableHome(props) {
  return (
    <OrderStepProvider>
      <TableContent {...props} />
    </OrderStepProvider>
  );
}

export default TableHome;
