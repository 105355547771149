// Modal.js
import React, {useState} from 'react';
import '../css/invoiceEditModal.css'; // Create styles for modal as needed

const Modal = ({ title, value, onSave, onClose }) => {
  const [inputValue, setInputValue] = useState(value);

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSubmit = () => {
    onSave(inputValue);
  };

  return (
    <div className="modal-overlay-invoice">
      <div className="modal-content-invoice">
        <h2>{title}</h2>
        <textarea
          value={inputValue}
          onChange={handleChange}
          rows={5} // Set the initial number of rows
        />
        <button onClick={handleSubmit}>Save</button>
        <button onClick={onClose}>Cancel</button>
      </div>
    </div>
  );
};

export default Modal;

